import { useToast } from '@elements/Toast';
import { AccountType } from '@models/UserAccount';
import { KoraApi, setCookies } from '@services/api';
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';

export default function useGoogleCallback(
  mode: 'register' | 'login',
  accessToken?: string,
  accountType?: AccountType
) {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const { mutate } = useAccountContext();
  const { error: errorToast, success: successToast } = useToast();
  const { t } = useTranslation('register');
  const navigate = useNavigate();

  const callback = async () => {
    setLoading(true);
    try {
      const response = await KoraApi.post('/auth/google-callback', {
        mode,
        accessToken,
        accountType
      });
      setCookies({ ...response.data });
      mutate();
      setSuccess(true);
      successToast(t('google_success'));
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        // if 404
        if (error.response?.status === 404) {
          navigate('/register');
        } else {
          errorToast(
            `${t('google_failed', { error: error.message ?? 'unknown' })}`
          );
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!accessToken) return;
    callback();
  }, [accessToken]);

  const memoized = useMemo(() => {
    return { loading, success };
  }, [loading, success]);
  return memoized;
}
