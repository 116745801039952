import { GoogleLoginButton } from '@components/auth/GoogleLoginButton';
import { RoundedContainer } from '@components/RoundedContainer';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { Input } from '@elements/Input';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import useLogin from '@services/auth/use-login';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';

export const LoginPage = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const { isLoggedIn } = useAccountContext();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  const { login } = useLogin();

  const isFormValid = () => {
    return email !== '' && password !== '';
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter' && isFormValid()) {
      login(email, password);
    }
  };

  return (
    <>
      <MetaTags title="Login" />
      <Container className="mt-[-44px] flex h-full items-center justify-center">
        <RoundedContainer className="min-w-[400px]">
          <div className="mb-6 w-full text-center">
            <Typography variant="h1" className="text-xl">
              {t('form_title')}
            </Typography>
            <Typography
              variant="h2"
              className="mt-2 !text-sm !font-normal text-primary"
            >
              {t('form_subtitle')}
            </Typography>
          </div>
          <div className="mb-5 flex flex-col gap-4">
            <Input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={e => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <div className="w-full">
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <Link to="/forgot-password">
                <Button
                  variant="info"
                  className="w-full border-none px-0 py-1 text-right text-sm font-semibold text-[#000000] underline"
                >
                  {t('password_forgotten')}
                </Button>
              </Link>
            </div>
            <Button
              disabled={!isFormValid()}
              variant="primary"
              onClick={() => {
                if (isFormValid()) {
                  login(email, password);
                }
              }}
            >
              {t('login_button')}
            </Button>
            <GoogleLoginButton mode={'login'} label={t('log_in_with_google')} />
          </div>
          <div className="text-center">
            <Typography
              variant="body1"
              className="mb-1 mt-2 text-sm !font-normal text-primary"
            >
              {t('dont_have_account')}{' '}
            </Typography>
            <Link
              to="/register"
              className="text-sm font-semibold text-[#000000]"
            >
              {t('register')}
            </Link>
          </div>
        </RoundedContainer>
      </Container>
    </>
  );
};
