import { useToast } from '@elements/Toast';
import { MatchingStatus } from '@models/Match';
import { useValidationPopup } from '@providers/ValidationPopupProvider';
import { KoraApi } from '@services/api';
import { useTranslation } from 'react-i18next';
import { useCandidateFunnel } from './use-candidate-funnel';
import { useCandidateMatches } from './use-candidate-matches';

interface PatchOptions {
  status: MatchingStatus;
  onSuccess?: () => void;
}

interface PatchWithConfirmOptions {
  title?: string;
  description?: string;
  status: MatchingStatus;
  onSuccess?: () => void;
}

export const usePatchCandidateMatch = (matchId: string) => {
  const { mutate } = useCandidateMatches();
  const { mutate: mutateFunnel } = useCandidateFunnel();
  const { success } = useToast();
  const { t } = useTranslation('recommended_jobs');
  const { show } = useValidationPopup();

  const patch = async ({ status, onSuccess }: PatchOptions) => {
    await KoraApi.patch(`/candidate/matches/${matchId}`, {
      status
    });
    success(t('apply_success'));
    mutate();
    mutateFunnel();
    onSuccess?.();
  };

  const patchWithConfirm = ({
    status,
    title,
    description,
    onSuccess
  }: PatchWithConfirmOptions) => {
    show({
      title: title ?? t('apply_confirm_title'),
      description: description ?? t('apply_confirm_description'),
      callback: async () => {
        await patch({ status, onSuccess });
      }
    });
  };

  return { patch, patchWithConfirm };
};
