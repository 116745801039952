import { Collapse } from '@elements/Collapse';
import { Typography } from '@elements/Typography';
import { Vacancy } from '@models/Vacancy';
import { useTranslation } from 'react-i18next';
import { VacancyField } from './VacancyField';

export const VacancyDraft = ({
  vacancy,
  defaultOpen = false,
  hideTitle = false
}: {
  vacancy: Omit<Vacancy, 'id'>;
  defaultOpen?: boolean;
  hideTitle?: boolean;
}) => {
  const { t } = useTranslation('vacancies');
  return (
    <>
      {!hideTitle && vacancy.title && (
        <VacancyField vacancy={vacancy} fieldName="title" />
      )}
      {vacancy.description && (
        <VacancyField vacancy={vacancy} fieldName="description" />
      )}

      <div className="flex w-full justify-between">
        {(vacancy.salaryMax || vacancy.salaryMin) && (
          <VacancyField vacancy={vacancy} fieldName="salary" />
        )}
        {vacancy.homeworkDays && (
          <VacancyField vacancy={vacancy} fieldName="homeworkDays" />
        )}
      </div>

      {vacancy.benefits && (
        <Collapse
          title={<Typography variant="h5">{t('benefits')}</Typography>}
          defaultOpen={defaultOpen}
        >
          <VacancyField vacancy={vacancy} fieldName="benefits" />
        </Collapse>
      )}
      {vacancy.requiredSkills && (
        <Collapse
          title={<Typography variant="h5">{t('required_skills')}</Typography>}
          defaultOpen={defaultOpen}
        >
          <VacancyField vacancy={vacancy} fieldName="requiredSkills" />
        </Collapse>
      )}
      {vacancy.responsibilities && (
        <Collapse
          title={<Typography variant="h5">{t('responsibilities')}</Typography>}
          defaultOpen={defaultOpen}
        >
          <VacancyField vacancy={vacancy} fieldName="responsibilities" />
        </Collapse>
      )}
    </>
  );
};
