import Add from './add.svg?react';
import Attachment from './attachment.svg?react';
import BackArrow from './back-arrow.svg?react';
import Bell from './bell.svg?react';
import Chat from './chat.svg?react';
import CheckCircle from './check-circle.svg?react';
import Chevron from './chevron.svg?react';
import ErrorCircle from './error-circle.svg?react';
import Glasses from './glasses.svg?react';
import Info from './info.svg?react';
import Link from './link.svg?react';
import Pdf from './pdf.svg?react';
import Pencil from './pencil.svg?react';
import Search from './search.svg?react';
import SendArrow from './send-arrow.svg?react';
import Trash from './trash.svg?react';

const ChevronUp = ({ ...props }) => (
  <Chevron style={{ transform: 'rotate(180deg)' }} {...props} />
);

const ChevronLeft = ({ ...props }) => (
  <Chevron style={{ transform: 'rotate(90deg)' }} {...props} />
);

const ChevronRight = ({ ...props }) => (
  <Chevron style={{ transform: 'rotate(-90deg)' }} {...props} />
);

export {
  Add as AddIcon,
  Attachment,
  BackArrow,
  Bell,
  Chat as ChatIcon,
  CheckCircle,
  Chevron as ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ErrorCircle,
  Glasses,
  Info,
  Link,
  Pdf,
  Pencil,
  Search,
  SendArrow,
  Trash
};
