import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import PercentageSlider from '@elements/PercentageSlider';
import { Typography } from '@elements/Typography';
import { Match, MatchingStatus } from '@models/Match';
import { usePatchCandidateMatch } from '@services/candidate/use-patch-candidate-match';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface VacancyMatchCardProps {
  match: Match;
}

export const VacancyMatchCard = ({ match }: VacancyMatchCardProps) => {
  const { t } = useTranslation('recommended_jobs');
  const vacancy = match.vacancy;
  const { patchWithConfirm } = usePatchCandidateMatch(match.id);

  return (
    <Card>
      <div className="flex flex-col">
        <div className="mb-8 flex flex-col gap-4">
          <Typography variant="h5" className="line-clamp-2 h-[48px] leading-6">
            {vacancy.title}
          </Typography>
          <Typography variant="body1" className="text-light">
            {vacancy.employer?.companyName}
          </Typography>
        </div>
        <PercentageSlider percentage={match.percentage} />
        <div className="mt-8 flex justify-between gap-4">
          <Link to={`/jobs/${vacancy.id}`} className="w-6/12">
            <Button variant="primary" size="sm" className="h-full w-full">
              {t('job_details')}
            </Button>
          </Link>
          <Button
            variant="info"
            size="sm"
            className="w-6/12"
            onClick={() =>
              patchWithConfirm({
                status: MatchingStatus.APPLIED,
                title: t('apply_confirm_title'),
                description: t('apply_confirm_description')
              })
            }
          >
            {t('apply')}
          </Button>
        </div>
      </div>
    </Card>
  );
};
