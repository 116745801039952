import { ChatMessage, SenderType } from '@models/chatMessage';
import { ChatRoom } from '@models/ChatRoom';
import { KoraApi } from '@services/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import useSWR from 'swr';

export interface About {
  aboutType: 'UserAccount' | 'Vacancy';
  aboutId: string;
}

interface ChatProps {
  onMessage?: () => void;
  about: About;
}

export const useChat = ({ onMessage, about }: ChatProps) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isAnswering, setIsAnswering] = useState(false);

  const { chatRoom, ...chatRoomSWR } = useChatRoom(about);
  const { messages, ...messagesSWR } = useChatMessages(chatRoom?.id);

  const completeConversation = async () => {
    if (!chatRoom?.id) return;
    setIsAnswering(true);
    await KoraApi.post(`/chat/${chatRoom.id}/complete`, {});
  };

  const sendMessage = useCallback(
    async (message: string) => {
      if (!chatRoom?.id) return;
      setIsAnswering(true);
      await KoraApi.post(`/chat/${chatRoom.id}/messages`, { message });
      messagesSWR.mutate();
    },
    [chatRoom?.id, messagesSWR]
  );

  useEffect(() => {
    if (chatRoom?.id && !socket) {
      initChat(chatRoom.id, messagesSWR.mutate, setIsAnswering, setSocket);
    }
  }, [chatRoom?.id, socket, messagesSWR.mutate]);

  return useMemo(() => {
    onMessage?.();
    const lastMessage = messages.sort((a, b) => {
      return b.createdAt.getTime() - a.createdAt.getTime();
    })[0];
    const _isAnswering =
      isAnswering ||
      messages.length === 0 ||
      lastMessage?.senderType === SenderType.USER ||
      lastMessage?.hasFollowUp;

    return {
      messages,
      sendMessage,
      canComplete: chatRoom?.canComplete,
      completeConversation,
      isAnswering: _isAnswering,
      isLoading: chatRoomSWR.isLoading || messagesSWR.isLoading,
      error: chatRoomSWR.error || messagesSWR.error
    };
  }, [
    chatRoom,
    messages,
    sendMessage,
    completeConversation,
    isAnswering,
    chatRoomSWR.isLoading,
    messagesSWR.isLoading,
    chatRoomSWR.error,
    messagesSWR.error,
    onMessage
  ]);
};

// Separate hooks for chat room and messages
const useChatRoom = (about: About) => {
  const { data, mutate, ...rest } = useSWR(
    `/chat?aboutType=${about.aboutType}&aboutId=${about.aboutId}`
  );
  const chatRoom = data ? ChatRoom.fromJson(data) : null;

  useEffect(() => {
    if (!chatRoom?.id) return;

    console.log('Listening to chat-${chatRoom.id}');
    const socket = io(import.meta.env.VITE_API_URL);
    socket.emit('join', `chat-${chatRoom.id}`);

    socket.on(`message`, () => {
      console.log('message');
      mutate();
    });

    return () => {
      socket.disconnect();
    };
  }, [chatRoom?.id, mutate]);

  return { chatRoom, ...rest };
};

const useChatMessages = (chatRoomId?: string) => {
  const { data, ...rest } = useSWR(
    chatRoomId ? `/chat/${chatRoomId}/messages` : null
  );
  const messages: ChatMessage[] =
    data?.map((message: any) => ChatMessage.fromJson(message)) || [];

  return { messages, ...rest };
};

// Separate function for initializing chat
const initChat = (
  chatRoomId: string,
  mutateMessages: () => void,
  setIsAnswering: (value: boolean) => void,
  setSocket: (socket: Socket) => void
) => {
  const newSocket = io(import.meta.env.VITE_API_URL);
  newSocket.emit('join', chatRoomId);
  newSocket.on('connection', socket => {
    socket.join(chatRoomId);
  });
  newSocket.on('message', (message: any) => {
    mutateMessages();
    try {
      const response = JSON.parse(message);
      setIsAnswering(Boolean(response.hasFollowUp));
    } catch (error) {
      console.error('Error parsing message', error);
      setIsAnswering(false);
    }
  });
  setSocket(newSocket);
};
