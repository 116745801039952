import { ChatBox } from '@components/chat/ChatBox';
import { BackButton } from '@elements/BackButton';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { ProgressBar } from '@elements/ProgressBar';
import { Typography } from '@elements/Typography';
import { ErrorCircle } from '@icons/index';
import { Candidate } from '@models/candidate';
import { Status } from '@models/Status';
import { useChat } from '@services/chat/use-chat';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';

export const CandidateIntakePage = () => {
  const { t } = useTranslation('chat', {
    keyPrefix: 'candidate_intake'
  });
  const { user } = useAccountContext<Candidate>();
  const [currentStep, setCurrentStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [canSearchJobs, setCanSearchJobs] = useState(false);

  const { completeConversation, canComplete } = useChat({
    about: { aboutType: 'UserAccount', aboutId: user!.id }
  });

  const handleCompleteAction = useCallback(async () => {
    try {
      await completeConversation();
      // You might want to add some logic here after the conversation is completed
      // For example, updating the UI or navigating to another page
    } catch (error) {
      console.error('Error completing conversation:', error);
    }
  }, [completeConversation]);

  useEffect(() => {
    let _step = 1;
    const fields = user?.accountInfo;
    if (fields) {
      const { firstName, lastName, phone, birthDate, address, resume } = fields;
      if (firstName && lastName && phone && birthDate && address) {
        _step = 2;
      }
      if (resume && resume.parsedFilePath) {
        _step = 3;
      }
      if (resume && resume.parsedFilePath && canComplete) {
        setIsComplete(true);
      }
      if (user.accountInfo.summaryUrl) {
        setCanSearchJobs(true);
      }
    }
    setCurrentStep(_step);
  }, [user, canComplete]);

  const steps = [
    { label: t('progress_bar.step_1'), active: currentStep === 1 },
    { label: t('progress_bar.step_2'), active: currentStep === 2 },
    { label: t('progress_bar.step_3'), active: currentStep === 3 }
  ];

  const Header = () => {
    return (
      <Container>
        <div className="grid grid-cols-[1fr,4fr,1fr] py-4">
          <div className="flex items-start justify-start gap-2 font-bold">
            <BackButton />
          </div>
          <div className="w-full text-center">
            <Typography variant="h2" className="mb-3 text-primary">
              {t('title')}
            </Typography>
            <Typography variant="body1" className="mb-4 text-secondary">
              {t('description')}
            </Typography>
            <ProgressBar steps={steps} />
            {user?.accountInfo?.resume?.status === Status.ERROR && (
              <p className="flex w-full items-center justify-center text-error">
                <ErrorCircle className="mr-2 h-4 w-4 fill-error" />
                {t('resume_error')}
              </p>
            )}
          </div>
        </div>
      </Container>
    );
  };
  return (
    <div className="flex h-full max-h-[calc(100vh-90px)] w-full flex-col">
      <Header />
      {user && (
        <ChatBox
          about={{ aboutType: 'UserAccount', aboutId: user.id }}
          cta={
            isComplete ? (
              <>
                <Button
                  variant="info"
                  className="font-medium"
                  onClick={handleCompleteAction}
                >
                  {t('complete_profile_cta')}
                </Button>
                {canSearchJobs && (
                  <Link to="/jobs">
                    <Button variant="info" className="ms-3 font-medium">
                      {t('search_jobs_cta')}
                    </Button>
                  </Link>
                )}
              </>
            ) : undefined
          }
        />
      )}
    </div>
  );
};
