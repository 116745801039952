import React from 'react';
import { PercentageLabel } from './PercentageLabel';
import { Typography } from './Typography';

interface PercentageSliderProps {
  percentage: number;
  size?: 'sm' | 'md';
}

const PercentageSlider: React.FC<PercentageSliderProps> = ({
  percentage,
  size = 'md'
}) => {
  const dots = (100 - percentage) / 10 + ((100 - percentage) % 10 > 0 ? 1 : 0);

  const sizeClasses = {
    sm: {
      container: 'h-6',
      circle: 'h-9 w-9',
      circleOffset: 'calc(${percentage}% - 1rem)',
      text: 'text-xs',
      dots: 'h-3 w-3 top-1.5'
    },
    md: {
      container: 'h-8',
      circle: 'h-11 w-11',
      circleOffset: 'calc(${percentage}% - 1.5rem)',
      text: 'text-sm',
      dots: 'h-4 w-4 top-2'
    }
  }[size];

  return (
    <div className="relative flex items-center">
      <div
        className={`relative ${sizeClasses.container} w-full overflow-hidden rounded-full bg-[#C0CDFB80]`}
      >
        <div
          className="absolute flex h-full w-full items-center justify-center rounded-full bg-[#C0CDFB] transition-all duration-300"
          style={{ width: `${percentage}%` }}
        >
          {percentage > 50 && (
            <PercentageLabel
              percentage={percentage}
              className={`z-10 font-medium text-[#3B55F6] ${sizeClasses.text}`}
            />
          )}
        </div>
      </div>
      <div
        className={`absolute z-10 flex items-center justify-center rounded-full bg-[#C0CDFB] text-[#3B55F6] transition-all duration-300 ${sizeClasses.circle}`}
        style={{
          left: sizeClasses.circleOffset.replace(
            '${percentage}',
            percentage.toString()
          )
        }}
      >
        <Typography
          variant="body1"
          className={`pl-1 text-center font-semibold text-[#3B55F6] ${sizeClasses.text}`}
        >
          {percentage}%
        </Typography>
      </div>
      {Array.from({ length: dots }).map((_, index) => (
        <div
          key={index}
          className={`absolute rounded-full bg-white ${sizeClasses.dots}`}
          style={{ right: `calc(${index * 10}% + 0.5rem)` }}
        />
      ))}
    </div>
  );
};

export default PercentageSlider;
