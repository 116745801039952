import { Match, MatchingStatus } from '@models/Match';
import useSWR from 'swr';

interface Funnel {
  [MatchingStatus.APPLIED]: Match[];
  [MatchingStatus.DECLINED]: Match[];
  [MatchingStatus.INVITED]: Match[];
  [MatchingStatus.REJECTED]: Match[];
  [MatchingStatus.INTERVIEW]: Match[];
  total: number;
}

export const CandidateFunnelStatuses = [
  MatchingStatus.APPLIED,
  MatchingStatus.DECLINED,
  MatchingStatus.INVITED,
  MatchingStatus.REJECTED,
  MatchingStatus.INTERVIEW
] as const;

export type CandidateFunnelStatus = (typeof CandidateFunnelStatuses)[number];

export const useCandidateFunnel = () => {
  const { data, ...swr } = useSWR(`/candidate/funnel`);

  const matches: Match[] = data?.map((match: any) => {
    return Match.fromJson(match);
  });

  const funnel: Funnel = {
    [MatchingStatus.APPLIED]: [],
    [MatchingStatus.DECLINED]: [],
    [MatchingStatus.INVITED]: [],
    [MatchingStatus.REJECTED]: [],
    [MatchingStatus.INTERVIEW]: [],
    total: matches?.length || 0
  };

  matches?.forEach(match => {
    let status = match.status;
    if (status === MatchingStatus.DECLINED_INTERVIEW_BY_CANDIDATE) {
      status = MatchingStatus.DECLINED;
    }
    if (status === MatchingStatus.DECLINED_INTERVIEW_BY_EMPLOYER) {
      status = MatchingStatus.REJECTED;
    }
    if (
      status !== MatchingStatus.MATCHED &&
      status !== MatchingStatus.JOB_TAKEN
    ) {
      funnel[status].push(match);
    }
  });

  return {
    funnel,
    ...swr
  };
};
