import { Popup } from '@elements/Popup';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect
} from 'react';

interface PopupContextType {
  show: ({
    body,
    closeButton
  }: {
    body: React.ReactNode;
    closeButton?: boolean;
  }) => void;
  hide: () => void;
}

const PopupContext = createContext<PopupContextType | undefined>(undefined);

export const PopupProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [closeButton, setCloseButton] = React.useState(true);
  const [popupBody, setPopupBody] = React.useState<React.ReactNode>();
  const [pathname, setPathname] = React.useState(window.location.pathname);

  useEffect(() => {
    if (pathname !== window.location.pathname) {
      hide();
    }
  }, [pathname]);

  const show = useCallback(
    ({
      body,
      closeButton: propsCloseButton
    }: {
      body: React.ReactNode;
      closeButton?: boolean;
    }) => {
      if (isOpen) {
        throw new Error('Popup already open');
      }
      setIsOpen(true);
      setPopupBody(body);
      setCloseButton(propsCloseButton === undefined ? true : propsCloseButton);
    },
    []
  );

  const hide = useCallback(() => {
    setIsOpen(false);
    setPopupBody(undefined);
    setCloseButton(true);
  }, []);

  return (
    <PopupContext.Provider value={{ show, hide }}>
      {children}
      <Popup isOpen={isOpen} onClose={hide} closeButton={closeButton}>
        <div className="flex flex-col gap-4">{popupBody}</div>
      </Popup>
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  const context = useContext(PopupContext);
  if (!context) {
    throw new Error('usePopup must be used within a PopupProvider');
  }
  return context;
};
