import { RoundedContainer } from '@components/RoundedContainer';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Container } from '@elements/Container';
import { EmailInput } from '@elements/EmailInput';
import { Input } from '@elements/Input';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { useQueryParams } from '@hooks/use-query-params';
import { useAccountContext } from '@providers/UserContext';
import { useRefer } from '@services/use-refer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

export const ReferPage = () => {
  const { t } = useTranslation('referrals');
  const { email: queryEmail } = useQueryParams();
  const { user } = useAccountContext();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const { refer, canRefer, isLoading } = useRefer({
    senderEmail: user?.email ?? queryEmail,
    contactName: name,
    contactEmail: email
  });
  return (
    <Container className="mt-[-1%] flex h-full flex-col items-center justify-center gap-4">
      <MetaTags title={t('refer_friend')} />
      <RoundedContainer className="flex w-full max-w-[930px] flex-col items-center p-[50px_100px]">
        <Typography variant="h1" color="dark" className="mb-10 !text-[3.5rem]">
          {t('refer_friend')}
        </Typography>
        <Typography
          variant="h3"
          color="dark"
          className="mb-10 !px-8 text-center !text-[1.5rem] !font-bold"
        >
          {t('refer_friend_subtitle')}
        </Typography>
        <Typography
          variant="h5"
          color="dark"
          className="text-center !font-[700]"
        >
          {t('refer_friend_description')}
        </Typography>
        <div className="my-10 flex w-full flex-col gap-4 md:px-[60px]">
          <Typography variant="body1" color="dark">
            {t('refer_friend_form_title')}
          </Typography>
          <Input
            placeholder={t('friend_name')}
            value={name}
            onChange={e => setName(e.target.value)}
          />
          <EmailInput
            placeholder={t('friend_email')}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <div className="flex justify-end">
            <Button
              disabled={!canRefer || isLoading}
              variant="secondary"
              className="!rounded !px-10 !py-4"
              onClick={() => {
                refer();
                setName('');
                setEmail('');
              }}
            >
              {isLoading ? (
                <ClipLoader size={16} color="#fff" />
              ) : (
                t('refer_friend_button')
              )}
            </Button>
          </div>
        </div>
        <Card className="w-full">
          <Typography
            variant="body1"
            color="dark"
            className="text-lg font-[500]"
          >
            {t('refer_friend_outro')}
          </Typography>
        </Card>
      </RoundedContainer>
    </Container>
  );
};
