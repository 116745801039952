import { ReactNode, useEffect, useRef } from 'react';
import { Typography } from './Typography';

export interface IInfiniteScroll {
  isLoadingInitial: boolean;
  isLoadingMore: boolean;
  isCompleted: boolean;
  children: ReactNode;
  onLoadMore: () => void | Promise<void>;
  intersectionObserverInit?: IntersectionObserverInit;
  isCompletedComponent?: ReactNode;
  isCompletedMessage?: string;
}

const InfiniteScroll = ({
  isLoadingInitial,
  isLoadingMore,
  isCompleted,
  children,
  onLoadMore,
  intersectionObserverInit = { rootMargin: '100px' },
  isCompletedComponent,
  isCompletedMessage
}: IInfiniteScroll) => {
  const observerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isCompleted || isLoadingInitial || isLoadingMore) return;

    const observer = new IntersectionObserver(async entries => {
      if (entries[0].isIntersecting) {
        await onLoadMore();
      }
    }, intersectionObserverInit);

    const currentObserverRef = observerRef.current;

    if (currentObserverRef) observer.observe(currentObserverRef);

    return () => {
      if (currentObserverRef) observer.unobserve(currentObserverRef);
      observer.disconnect();
    };
  }, [
    intersectionObserverInit,
    isCompleted,
    isLoadingInitial,
    isLoadingMore,
    onLoadMore
  ]);

  return (
    <div>
      {children}
      {isLoadingMore && (
        <div className="tw-my-5 tw-flex tw-items-center tw-justify-center tw-space-x-1">
          <span className="tw-sr-only">Loading...</span>
          <div className="tw-h-2 tw-w-2 tw-animate-bounce tw-rounded-full tw-bg-[#CCCCCC] [animation-delay:-0.3s]"></div>
          <div className="tw-h-2 tw-w-2 tw-animate-bounce tw-rounded-full tw-bg-[#CCCCCC] [animation-delay:-0.15s]"></div>
          <div className="tw-h-2 tw-w-2 tw-animate-bounce tw-rounded-full tw-bg-[#CCCCCC]"></div>
        </div>
      )}
      {isCompleted &&
        (isCompletedComponent ||
          (isCompletedMessage && (
            <div className="tw-mt-3 tw-flex tw-justify-center">
              <Typography variant="body1">{isCompletedMessage}</Typography>
            </div>
          )))}
      <div ref={observerRef}></div>
    </div>
  );
};

export default InfiniteScroll;
