import { Match } from '@models/Match';
import useSWR from 'swr';

export const useCandidateMatches = (props?: { vacancyId?: string }) => {
  let url = `/candidate/matches`;
  if (props?.vacancyId) {
    url = `${url}?vacancyId=${props.vacancyId}`;
  }
  const { data, ...swr } = useSWR(url);

  const matches: Match[] = data?.map((match: any) => {
    return Match.fromJson(match);
  });

  return {
    matches,
    ...swr
  };
};
