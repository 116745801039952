import { RoundedContainer } from '@components/RoundedContainer';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { Input } from '@elements/Input';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { useForgotPassword } from '@services/auth/use-forgot-password';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useAccountContext } from 'src/providers/UserContext';

export const ForgotPasswordPage = () => {
  const { t } = useTranslation('login');
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const { requestReset, loading } = useForgotPassword();

  const { isLoggedIn } = useAccountContext();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  const isFormValid = () => {
    return email !== '';
  };

  const handleSendResetLink = async () => {
    if (isFormValid()) {
      await requestReset(email);
      navigate(`/login`);
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === 'Enter') {
      handleSendResetLink();
    }
  };

  return (
    <>
      <MetaTags title="Login" />
      <Container className="mt-[-44px] flex h-full items-center justify-center">
        <RoundedContainer className="max-w-[500px]">
          <div className="mb-6 w-full text-center">
            <Typography variant="h1" className="text-xl">
              {t('forgot_password.form_title')}
            </Typography>
            <Typography
              variant="h2"
              className="mt-6 !text-sm !font-normal text-primary"
            >
              {t('forgot_password.form_subtitle')}
            </Typography>
          </div>
          <div className="mb-5 flex flex-col gap-4">
            <Input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={e => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
            />

            <Button
              disabled={!isFormValid() || loading}
              variant="primary"
              onClick={handleSendResetLink}
            >
              {loading ? (
                <ClipLoader color="#fff" size={16} />
              ) : (
                t('forgot_password.form_button')
              )}
            </Button>
          </div>
          <div className="text-center">
            <Typography
              variant="body1"
              className="mb-1 mt-2 text-sm !font-normal text-primary"
            >
              {t('dont_have_account')}{' '}
            </Typography>
            <Link
              to="/register"
              className="text-sm font-semibold text-[#000000]"
            >
              {t('register')}
            </Link>
          </div>
        </RoundedContainer>
      </Container>
    </>
  );
};
