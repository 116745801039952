import { Button } from '@elements/Button';
import { Input } from '@elements/Input';
import { Typography } from '@elements/Typography';
import { Candidate } from '@models/candidate';
import { usePopup } from '@providers/PopupProvider';
import { useAccountContext } from '@providers/UserContext';
import { useCandidatePatch } from '@services/user/use-candidate-patch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const LinkedinPopup = () => {
  const { t: tGeneric } = useTranslation('generic');
  const { t: tProfile } = useTranslation('profile');
  const { user } = useAccountContext<Candidate>();
  const [linkedinUrl, setLinkedinUrl] = useState<string | undefined>(
    user?.accountInfo?.linkedinUrl ?? undefined
  );
  const { patch } = useCandidatePatch({ linkedinUrl });
  const { hide } = usePopup();

  return (
    <>
      <div className="flex flex-col gap-4">
        <Typography variant="h3" className="mb-4">
          {tProfile('linked_in_link_title')}
        </Typography>
        <Input
          label="URL"
          variant="compact"
          value={linkedinUrl}
          onChange={e => setLinkedinUrl(e.target.value)}
        />
        <div className="mt-2 grid grid-cols-2 gap-4">
          <Button variant="info" size="sm" className="w-full" onClick={hide}>
            {tGeneric('cancel')}
          </Button>
          <Button
            variant="secondary"
            size="sm"
            className="w-full"
            onClick={() => {
              patch();
              hide();
            }}
          >
            {tGeneric('link')}
          </Button>
        </div>
      </div>
    </>
  );
};
