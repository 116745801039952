import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface PreviousPathContextType {
  previousPath: string | null;
  currentPath: string | null;
}

const PreviousPathContext = createContext<PreviousPathContextType>({
  previousPath: null,
  currentPath: null
});

export const usePreviousPath = () => useContext(PreviousPathContext);

interface PreviousPathProviderProps {
  children: React.ReactNode;
}

export const PreviousPathProvider: React.FC<PreviousPathProviderProps> = ({
  children
}) => {
  const [history, setHistory] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('/create')) return;
    if (location.pathname !== history[0]) {
      setHistory(prev => [location.pathname, ...prev.slice(0, 4)]);
    }
  }, [location.pathname]);

  return (
    <PreviousPathContext.Provider
      value={{ previousPath: history[1], currentPath: history[0] }}
    >
      {children}
    </PreviousPathContext.Provider>
  );
};
