import { PercentageLabel } from './PercentageLabel';

export interface PercentageBarProps {
  percentage: number;
}

export const PercentageBar = ({ percentage }: PercentageBarProps) => {
  return (
    <div className="bg-light-gray h-[40px] w-full rounded-full">
      <div
        className="flex h-full items-center justify-center rounded-full bg-secondary"
        style={{ width: `${percentage}%` }}
      >
        <PercentageLabel
          percentage={percentage}
          className="!text-lg text-white"
        />
      </div>
    </div>
  );
};
