import { useValidationPopup } from '@providers/ValidationPopupProvider';
import { KoraApi } from '@services/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useDeleteVacancy = (vacancyId: string) => {
  const [deleting, setDeleting] = useState(false);
  const { t } = useTranslation('vacancies');
  const { show } = useValidationPopup();

  const executeApiCall = async () => {
    setDeleting(true);
    await KoraApi.delete(`/vacancies/${vacancyId}`);
    setDeleting(false);
  };

  const deleteVacancy = () => {
    return new Promise(async resolve => {
      show({
        title: t('delete_validation'),
        callback: async () => {
          await executeApiCall();
          resolve(true);
        }
      });
    });
  };

  return { deleteVacancy, deleting };
};
