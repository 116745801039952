import { Typography } from '@elements/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Textra from 'react-textra';
export const HomeTitleSection = () => {
  const { t } = useTranslation('home');
  return (
    <>
      <div className="flex items-center justify-center !font-bold uppercase">
        <Typography variant="h1" className="!font-bold" color="dark">
          <Trans>{t('title')}</Trans>
        </Typography>
        <Typography
          variant="h1"
          className="ms-3 w-[180px] !font-bold"
          color="secondary"
        >
          <Textra
            effect="downTop"
            stopDuration={5000}
            data={t('title_slider', { returnObjects: true }) as string[]}
          />
        </Typography>
      </div>
      <div className="mt-4 flex flex-col items-center justify-center">
        <Typography
          variant="h2"
          className="text-xl !font-bold uppercase"
          color="dark"
        >
          {t('subtitle')}
        </Typography>
      </div>
    </>
  );
};
