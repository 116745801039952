import PlaceholderCard from '@assets/placeholder-card.svg';
import { VacancyCard } from '@components/vacancies/VacancyCard';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { PopupCTA } from '@elements/PopupCTA';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { Employer } from '@models/Employer';
import { usePopup } from '@providers/PopupProvider';
import { useEmployerVacancies } from '@services/employer/use-employer-vacancies';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

export const VacanciesPage = () => {
  const { t } = useTranslation('vacancies');
  const { user } = useAccountContext<Employer>();
  const { vacancies, isLoading } = useEmployerVacancies();
  const isMobile = useIsMobile();
  const { show } = usePopup();
  const [hasShownPopup, setHasShownPopup] = React.useState(false);

  useEffect(() => {
    if (hasShownPopup) return;

    if (user?.accountInfo?.intakeProgress !== 100) {
      show({
        body: (
          <PopupCTA
            title={t('intake_incomplete')}
            buttonText={t('go_to_intake')}
            link="/intake"
          />
        ),
        closeButton: false
      });
      setHasShownPopup(true);
    } else if (!isLoading && vacancies && vacancies.length === 0) {
      show({
        body: (
          <PopupCTA
            title={t('create_first_vacancy')}
            buttonText={t('create_vacancy')}
            link="/vacancies/create"
          />
        ),
        closeButton: false
      });
      setHasShownPopup(true);
    }
  }, [
    user?.accountInfo?.intakeProgress,
    vacancies,
    isLoading,
    show,
    t,
    hasShownPopup
  ]);

  const PlaceholderCards = () => (
    <>
      <img src={PlaceholderCard} className="w-full" />
      <img src={PlaceholderCard} className="w-full" />
      <img src={PlaceholderCard} className="w-full" />
    </>
  );

  return (
    <>
      <Container className={`${!isMobile && 'py-10'} relative`}>
        <div className="flex items-center justify-between">
          <Typography variant="h1">{t('vacancies')}</Typography>
          {vacancies && vacancies.length > 0 && (
            <Button variant="secondary" link="/vacancies/create">
              {t('create_new')}
            </Button>
          )}
        </div>
        <div className="mt-10 grid grid-cols-1 gap-4 md:grid-cols-3">
          {isLoading || vacancies.length === 0 ? (
            <PlaceholderCards />
          ) : (
            vacancies.map(vacancy => (
              <VacancyCard key={vacancy.id} vacancy={vacancy} />
            ))
          )}
        </div>
      </Container>
    </>
  );
};
