import { CheckCircle, ErrorCircle, Glasses } from '@icons/index';
import { Candidate } from '@models/candidate';
import { Status } from '@models/Status';
import { useAccountContext } from '@providers/UserContext';
import { useValidationPopup } from '@providers/ValidationPopupProvider';
import { useResumeUpload } from '@services/account/use-resume-upload';
import { useResumeDelete } from '@services/user/use-resume-delete';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { CTA } from '.';

export const ResumeCTA = () => {
  const { user } = useAccountContext<Candidate>();
  const { t } = useTranslation('profile', { keyPrefix: 'cta' });
  const { upload, isLoading: isUploadingResume } = useResumeUpload();
  const { deleteResume } = useResumeDelete();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { show } = useValidationPopup();

  const statusMessage = useMemo(() => {
    switch (user?.accountInfo?.resume?.status) {
      case Status.BUSY:
        return (
          <p className="flex items-center text-primary">
            <Glasses className="mr-2 h-4 w-4 fill-primary" />
            {t('reading_resume')}
          </p>
        );
      case Status.DONE:
        return (
          <p className="flex items-center text-primary">
            <CheckCircle className="mr-2 h-4 w-4 fill-primary" />
            {t('resume_success')}
          </p>
        );
      case Status.ERROR:
        return (
          <p className="flex items-center text-error">
            <ErrorCircle className="mr-2 h-4 w-4 fill-error" />
            {t('resume_error')}
          </p>
        );
      default:
        return null;
    }
  }, [user?.accountInfo?.resume?.status]);
  return (
    <>
      <CTA
        title={t('resume_title')}
        description={t('resume_description')}
        statusMessage={statusMessage}
        cta={
          <>
            {isUploadingResume && (
              <ClipLoader size={12} color="#000" className="mr-2" />
            )}
            {user?.accountInfo?.resume
              ? t('resume_button_remove')
              : t('resume_button')}
          </>
        }
        onClick={() => {
          if (user?.accountInfo?.resume) {
            show({
              title: t('resume_delete_confirm_title'),
              description: t('resume_delete_confirm_description'),
              callback: async () => {
                await deleteResume();
              }
            });
          } else fileInputRef.current?.click();
        }}
      />
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="application/pdf"
        onChange={e => upload(e.target.files?.[0] ?? null)}
      />
    </>
  );
};
