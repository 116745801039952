import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string | ReactElement | React.ReactNode;
  className?: string;
  variant?: 'info' | 'primary' | 'secondary' | 'void';
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  [key: string]: any;
}

export const Button = ({
  children,
  variant = 'info',
  className,
  disabled,
  size = 'md',
  link,
  ...props
}: IButton) => {
  const paddingMap = {
    xs: 'p-[10px_24px]',
    sm: 'p-[6px_24px]',
    md: 'p-[10px_24px]',
    lg: 'p-[10px_24px]'
  };

  const fontSizeMap = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg'
  };

  const baseClass = `rounded-[20px] font-medium ${paddingMap[size]} transition duration-200 ${!disabled ? 'hover:opacity-70' : 'cursor-not-allowed'}`;

  const variantClasses: { [key: string]: string } = {
    info: `border-[#000] border-[1px] ${disabled ? '!bg-[#E0E0E0] border-[#B0B0B0]' : 'bg-[#F5F5F0]'}`,
    primary: `bg-primary text-[#fff] ${disabled ? '!bg-[#C5C5C5] text-[#FFFFFF]' : ''}`,
    secondary: `bg-secondary text-[#fff] ${disabled ? '!bg-[#A3B1F9] text-[#E0E0E0]' : ''}`,
    void: '!py-0'
  };

  const renderButton = () => (
    <button
      className={`${baseClass} ${variantClasses[variant]} ${fontSizeMap[size]} ${className}`}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );

  return <>{link ? <Link to={link}>{renderButton()}</Link> : renderButton()}</>;
};
