import { useAccountContext } from '@providers/UserContext';
import { KoraApi } from '@services/api';

export const useUpdateLastReadNotificationDate = () => {
  const { mutate } = useAccountContext();
  const updateLastReadNotificationDate = async () => {
    await KoraApi.put('/notifications/read', {});
    mutate();
  };
  return { updateLastReadNotificationDate };
};
