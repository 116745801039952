import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Divider } from '@elements/Divider';
import PercentageSlider from '@elements/PercentageSlider';
import { Typography } from '@elements/Typography';
import { Match, MatchingStatus } from '@models/Match';
import { useCandidateMatches } from '@services/candidate/use-candidate-matches';
import { usePatchCandidateMatch } from '@services/candidate/use-patch-candidate-match';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface CandidateFunnelCardProps {
  match: Match;
}

export const CandidateFunnelCard = ({ match }: CandidateFunnelCardProps) => {
  const { t } = useTranslation('recommended_jobs');
  const vacancy = match.vacancy;

  const { mutate } = useCandidateMatches({ vacancyId: vacancy.id });
  const { patchWithConfirm } = usePatchCandidateMatch(match.id!);
  return (
    <Card className="!p-[24px_16px]">
      <div className="flex flex-col">
        <div className="mb-6 flex flex-col gap-2">
          <Typography variant="h5" className="line-clamp-2 text-base">
            {vacancy.title}
          </Typography>
          <Typography variant="body1" className="text-sm text-light">
            {vacancy.employer?.companyName}
          </Typography>
          {match.status === MatchingStatus.INVITED && (
            <Typography
              variant="body1"
              className="pt-3 text-sm text-light"
              color="primary"
            >
              <Trans>{t('interview_disclaimer')}</Trans>
            </Typography>
          )}
        </div>
        {match.status === MatchingStatus.INVITED ? (
          <Divider />
        ) : (
          <PercentageSlider size="sm" percentage={match.percentage} />
        )}
        <div className="mt-6 flex flex-col gap-4">
          {match.status === MatchingStatus.INVITED && (
            <Typography variant="body1" className="text-sm">
              {t('interview_accepted_verification_label')}
            </Typography>
          )}
          <Link to={`/jobs/${vacancy.id}`}>
            <Button variant="primary" size="sm" className="w-full">
              {t('job_details')}
            </Button>
          </Link>
          {match.status === MatchingStatus.INVITED && (
            <>
              <Button
                variant="info"
                size="sm"
                onClick={async () => {
                  await patchWithConfirm({
                    status: MatchingStatus.INTERVIEW,
                    title: t('accept_interview'),
                    description: t('accept_interview_confirm_description'),
                    onSuccess: () => {
                      mutate();
                    }
                  });
                }}
              >
                {t('accept_interview')}
              </Button>
              <Button
                variant="info"
                size="sm"
                onClick={async () => {
                  await patchWithConfirm({
                    status: MatchingStatus.DECLINED_INTERVIEW_BY_CANDIDATE,
                    title: t('decline_interview'),
                    description: t('decline_confirm_description'),
                    onSuccess: () => mutate()
                  });
                }}
              >
                {t('decline_interview')}
              </Button>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};
