import { MarkdownViewer } from '@elements/MarkdownViewer';
import { QuickAction } from '@models/chatMessage';
import { useAccountContext } from '@providers/UserContext';
import { ReactNode, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import loadComponents from './LoadQuickActions';
import ErrorQuickAction from './quick-actions/Error';
interface ChatBubbleProps {
  message: string | ReactNode;
  messageId: string;
  className?: string;
  quickActions?: QuickAction[];
  isUser: boolean;
  isLast?: boolean;
  sendMessage?: (message: string) => void;
  translatable: boolean;
}

interface ChatBaseBubbleProps {
  message: string | ReactNode;
  isUser: boolean;
  className?: string;
}

export const ChatBaseBubble = ({
  message,
  isUser,
  className
}: ChatBaseBubbleProps) => {
  const getBubbleClass = (isUser: boolean) => {
    let bubbleClass = `${className} max-w-[60%] rounded-[20px] p-[16px_24px] tracking-[0.6px] shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]`;
    return isUser
      ? `${bubbleClass} bg-[#DBE2FD] rounded-[20px_20px_0px_20px]`
      : `${bubbleClass} bg-[#fff] rounded-[20px_20px_20px_0px]`;
  };

  return (
    <div className={getBubbleClass(isUser)}>
      {typeof message === 'string' ? (
        <MarkdownViewer content={message} />
      ) : (
        message
      )}
    </div>
  );
};

const ChatBubble = ({
  message,
  isUser,
  quickActions,
  messageId,
  isLast,
  sendMessage,
  translatable,
  className
}: ChatBubbleProps) => {
  const [components, setComponents] = useState<any[]>([]);
  const { user } = useAccountContext();
  const { t } = useTranslation('chat');

  useEffect(() => {
    if (quickActions) {
      loadComponents(quickActions, messageId, sendMessage).then(setComponents);
    }
  }, [quickActions, sendMessage]);

  const renderMessage = (message: string | ReactNode) => {
    if (typeof message === 'string' && translatable) {
      message = t(message, { user: user?.accountInfo });
    }
    return (
      <div className={`flex ${isUser && 'justify-end'}`}>
        <ChatBaseBubble
          message={message}
          isUser={isUser}
          className={className}
        />
      </div>
    );
  };

  const renderQuickActions = () => {
    return (
      <Suspense fallback={<ErrorQuickAction />}>
        <div className="justify-left mt-2 flex max-w-[60%]">
          {components.map((component, index) => (
            <div
              key={`quick-action-${index}`}
              className="flex-1 rounded-[20px] bg-[#fff] p-[16px_24px] text-sm tracking-[0.6px] shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]"
            >
              {component}
            </div>
          ))}
        </div>
      </Suspense>
    );
  };

  return (
    <div className={className}>
      {message !== '/' && renderMessage(message)}
      {components.length > 0 && isLast && renderQuickActions()}
    </div>
  );
};

export default ChatBubble;
