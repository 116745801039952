import { Typography } from '@elements/Typography';
import { Vacancy } from '@models/Vacancy';
import { useTranslation } from 'react-i18next';

type VacancyFieldProps = {
  vacancy: Partial<Vacancy>;
  fieldName: keyof Vacancy | 'salary';
};

export const VacancyField = ({ vacancy, fieldName }: VacancyFieldProps) => {
  const { t } = useTranslation('vacancies');

  switch (fieldName) {
    case 'title':
      return vacancy.title ? (
        <Typography variant="h5" className="text-xl text-primary">
          {vacancy.title}
        </Typography>
      ) : null;

    case 'description':
      return vacancy.description ? (
        <Typography variant="body1">{vacancy.description}</Typography>
      ) : null;

    case 'salary':
      return vacancy.salaryMin || vacancy.salaryMax ? (
        <div className="flex gap-2">
          <Typography variant="body1" className="font-bold">
            {t('salary_range')}:
          </Typography>
          {vacancy.salaryMin && (
            <Typography variant="body1">€{vacancy.salaryMin}</Typography>
          )}
          {vacancy.salaryMax && vacancy.salaryMin && (
            <Typography variant="body1">-</Typography>
          )}
          {vacancy.salaryMax && (
            <Typography variant="body1">€{vacancy.salaryMax}</Typography>
          )}
        </div>
      ) : null;

    case 'homeworkDays':
      return vacancy.homeworkDays ? (
        <div className="flex gap-2">
          <Typography variant="body1" className="font-bold">
            {t('homework_days')}:
          </Typography>
          <Typography variant="body1">{vacancy.homeworkDays}</Typography>
        </div>
      ) : null;

    case 'benefits':
      return vacancy.benefits && vacancy.benefits.length > 0 ? (
        <ul className="ml-4 list-disc">
          {vacancy.benefits.map(item => (
            <li key={item}>
              <Typography variant="body1">{item}</Typography>
            </li>
          ))}
        </ul>
      ) : null;

    case 'requiredSkills':
      return vacancy.requiredSkills && vacancy.requiredSkills.length > 0 ? (
        <ul className="ml-4 list-disc">
          {vacancy.requiredSkills.map(item => (
            <li key={item}>
              <Typography variant="body1">{item}</Typography>
            </li>
          ))}
        </ul>
      ) : null;

    case 'responsibilities':
      return vacancy.responsibilities && vacancy.responsibilities.length > 0 ? (
        <ul className="ml-4 list-disc">
          {vacancy.responsibilities.map(item => (
            <li key={item}>
              <Typography variant="body1">{item}</Typography>
            </li>
          ))}
        </ul>
      ) : null;

    default:
      return null;
  }
};
