import PlaceholderCard from '@assets/placeholder-card.svg';
import { EmployerFunnelCard } from '@components/funnel/EmployerFunnelCard';
import { FunnelFilters } from '@components/funnel/FunnelFilters';
import { Container } from '@elements/Container';
import { PopupCTA } from '@elements/PopupCTA';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { Employer } from '@models/Employer';
import { MatchingStatus } from '@models/Match';
import { usePopup } from '@providers/PopupProvider';
import { useAccountContext } from '@providers/UserContext';
import { useEmployerFunnel } from '@services/employer/use-employer-funnel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EmployerFunnelPage = () => {
  const { t } = useTranslation('funnel');
  const { user } = useAccountContext<Employer>();
  const [hasItems, setHasItems] = useState(false);
  const [selectedVacancyId, setSelectedVacancyId] = useState<string | null>(
    null
  );
  const [search, setSearch] = useState<string | null>(null);
  const isMobile = useIsMobile();
  const { funnel, isLoading } = useEmployerFunnel({
    vacancyIds: selectedVacancyId ? [selectedVacancyId] : undefined,
    search: search ?? undefined
  });
  const { show } = usePopup();

  useEffect(() => {
    setHasItems(
      prev => prev || Object.values(funnel).some(status => status.length > 0)
    );
  }, [funnel]);

  useEffect(() => {
    if (user?.accountInfo?.intakeProgress !== 100) {
      show({
        body: (
          <PopupCTA
            title={t('intake_incomplete')}
            buttonText={t('go_to_intake')}
            link="/intake"
          />
        ),
        closeButton: false
      });
    }
  }, [user?.accountInfo?.intakeProgress]);

  const order = [
    MatchingStatus.APPLIED,
    MatchingStatus.REJECTED,
    MatchingStatus.INVITED,
    MatchingStatus.DECLINED,
    MatchingStatus.INTERVIEW
  ];

  const PlaceholderCards = () => {
    const titles = [
      t('applied'),
      t('rejected'),
      t('invited'),
      t('declined'),
      t('interview')
    ];
    return (
      <>
        {Array.from({ length: 5 }).map((_, index) => (
          <FunnelColumn
            key={`${index}-placeholder`}
            title={titles[index]}
            showDivider={index !== 4}
          >
            <img src={PlaceholderCard} className="w-full" key={index} />
          </FunnelColumn>
        ))}
      </>
    );
  };

  const FunnelColumn = ({
    title,
    children,
    showDivider = true
  }: {
    title: string;
    children: React.ReactNode;
    showDivider?: boolean;
  }) => {
    return (
      <div
        className={`flex h-full flex-col gap-4 pr-3 ${
          showDivider ? 'border-r border-gray' : ''
        }`}
      >
        <Typography variant="h5" className="px-1 pb-5">
          {title}
        </Typography>
        <div className="flex flex-col gap-6">{children}</div>
      </div>
    );
  };

  return (
    <Container className={`${!isMobile && 'py-10'} relative`}>
      <div className="flex flex-col justify-between gap-6 pb-4">
        <Typography variant="h1">{t('recruitment_funnel')}</Typography>
      </div>
      {!isLoading && !hasItems ? (
        <div className="mt-10 flex gap-4">
          <PlaceholderCards />
        </div>
      ) : (
        <>
          <FunnelFilters
            selectedVacancyId={selectedVacancyId}
            onVacancyFilterChange={setSelectedVacancyId}
            onSearchChange={setSearch}
          />
          <div className="mt-10 grid h-[90%] grid-cols-1 gap-4 md:grid-cols-5">
            {order.map((status, index) => {
              if (
                status !== MatchingStatus.MATCHED &&
                status !== MatchingStatus.JOB_TAKEN
              ) {
                if (status === MatchingStatus.DECLINED_INTERVIEW_BY_CANDIDATE) {
                  status = MatchingStatus.DECLINED;
                }
                if (status === MatchingStatus.DECLINED_INTERVIEW_BY_EMPLOYER) {
                  status = MatchingStatus.REJECTED;
                }

                if (!order.includes(status as any)) {
                  return;
                }
                const validStatus = status as keyof typeof funnel;
                return (
                  <FunnelColumn
                    key={`${index}-${status}`}
                    title={t(validStatus.toLowerCase())}
                    showDivider={index !== 4}
                  >
                    {funnel[status].map(match => (
                      <EmployerFunnelCard match={match} key={match.id} />
                    ))}
                  </FunnelColumn>
                );
              }
            })}
          </div>
        </>
      )}
    </Container>
  );
};
