import { Button } from '@elements/Button';
import { Popup } from '@elements/Popup';
import { Typography } from '@elements/Typography';
import React, { createContext, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface ValidationPopupContextType {
  show: ({
    title,
    description,
    callback
  }: {
    title?: string;
    description?: string;
    callback?: () => Promise<void> | void;
  }) => void;
  hide: () => void;
}

const ValidationPopupContext = createContext<
  ValidationPopupContextType | undefined
>(undefined);

export const ValidationPopupProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [popupTitle, setPopupTitle] = React.useState<string>();
  const [popupDescription, setPopupDescription] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [pendingCallback, setPendingCallback] = React.useState<
    (() => Promise<void> | void) | undefined
  >();
  const { t } = useTranslation('generic');

  const show = useCallback(
    ({
      title,
      description,
      callback
    }: {
      title?: string;
      description?: string;
      callback?: () => Promise<void> | void;
    }) => {
      setIsOpen(true);
      setPopupTitle(title);
      setPopupDescription(description);
      setPendingCallback(() => callback);
    },
    []
  );

  const hide = useCallback(() => {
    setIsOpen(false);
    setPendingCallback(undefined);
  }, []);

  const handleConfirm = useCallback(async () => {
    if (pendingCallback) {
      try {
        setIsLoading(true);
        await pendingCallback();
      } catch (error) {
        console.error('Error in validation popup callback:', error);
      } finally {
        setIsLoading(false);
        hide();
      }
    } else {
      hide();
    }
  }, [pendingCallback, hide]);

  return (
    <ValidationPopupContext.Provider value={{ show, hide }}>
      {children}
      <Popup isOpen={isOpen} closeButton={false}>
        <div className="flex flex-col gap-4">
          <Typography variant="h3" className="mb-4">
            {popupTitle}
          </Typography>
          <Typography variant="body1" className="mb-4">
            {popupDescription}
          </Typography>
          <div className="flex justify-center gap-4">
            <Button variant="info" onClick={hide} disabled={isLoading}>
              {t('cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={handleConfirm}
              loading={isLoading}
            >
              {t('confirm')}
            </Button>
          </div>
        </div>
      </Popup>
    </ValidationPopupContext.Provider>
  );
};

export const useValidationPopup = () => {
  const context = useContext(ValidationPopupContext);
  if (!context) {
    throw new Error(
      'useValidationPopup must be used within a ValidationPopupProvider'
    );
  }
  return context;
};
