import { useTranslation } from 'react-i18next';
import { Typography } from './Typography';

export interface PercentageLabelProps {
  percentage: number;
  className?: string;
}

export const PercentageLabel = ({
  percentage,
  className
}: PercentageLabelProps) => {
  const { t } = useTranslation('funnel');

  const matchLabels: { min: number; label: string }[] = [
    { min: 0, label: '' },
    { min: 50, label: 'good_match' },
    { min: 75, label: 'impressive_match' }
  ];

  const label = matchLabels
    .sort((a, b) => b.min - a.min)
    .find(l => percentage >= l.min)?.label;

  return (
    <Typography
      variant="body1"
      className={`text-sm text-secondary ${className}`}
    >
      {t(label || '')}
    </Typography>
  );
};
