import { Match } from '@models/Match';
import useSWR from 'swr';

export const useFunnelItem = (id: string) => {
  const { data, error, ...swr } = useSWR(`/employer/funnel/${id}`);
  let match = null;
  if (data) {
    match = Match.fromJson(data);
  }
  return { match, error, ...swr };
};
