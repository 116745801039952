import { Bell } from '@icons/index';
import { useAccountContext } from '@providers/UserContext';
import { useUpdateLastReadNotificationDate } from '@services/notifications/set-last-read-date';
import { useNavigate } from 'react-router-dom';

export const NotificationBell = () => {
  const { user } = useAccountContext();
  const navigate = useNavigate();
  const { updateLastReadNotificationDate } =
    useUpdateLastReadNotificationDate();
  const mustShowBubble = Boolean(
    user?.amountUnreadNotifications && user.amountUnreadNotifications > 0
  );

  const handleClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    await updateLastReadNotificationDate();
    // if ctrl is pressed, open in new tab
    if (event.ctrlKey) {
      window.open('/notifications', '_blank');
    } else {
      navigate('/notifications');
    }
  };

  return (
    <div className="relative hover:cursor-pointer" onClick={handleClick}>
      <Bell />
      {mustShowBubble && (
        <div className="absolute right-0.5 top-0 z-10 flex h-2.5 w-2.5 animate-kora-pulse items-center justify-center rounded-full bg-secondary"></div>
      )}
    </div>
  );
};
