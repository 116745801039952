import { Colors } from '@utils/ColorUtils';
import { ClipLoader } from 'react-spinners';
import { BackButton } from './BackButton';
import { Container } from './Container';

interface ContainerWithBackButtonProps {
  children?: React.ReactNode | React.ReactNode[];
  loading?: boolean;
  onClickBackButton?: () => void;
}

export const ContainerWithBackButton = ({
  children,
  loading,
  onClickBackButton
}: ContainerWithBackButtonProps) => {
  return (
    <Container>
      {!loading ? (
        <div className="grid grid-cols-[1fr_6fr_1fr] items-start pt-4">
          <BackButton onClick={onClickBackButton} />
          <div className="w-full px-6">{children}</div>
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <ClipLoader color={Colors.primary} size={50} />
        </div>
      )}
    </Container>
  );
};
