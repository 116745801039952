import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { Divider } from '@elements/Divider';
import { Typography } from '@elements/Typography';
import { MatchingStatus } from '@models/Match';
import { useFunnelItem } from '@services/employer/use-funnel-item';
import { usePatchEmployerMatch } from '@services/employer/use-patch-employer-match';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

export const InviteCandidatePage = () => {
  const { matchId } = useParams();
  const { match } = useFunnelItem(matchId!);
  const { t } = useTranslation('funnel');
  const { t: tLiterals } = useTranslation('literals');
  const { patch } = usePatchEmployerMatch(matchId!);

  const setInvited = async () => {
    await patch({
      status: MatchingStatus.INVITED,
      onSuccessMessage: t('invite_success')
    });
  };

  return (
    <ContainerWithBackButton loading={!match}>
      <Typography
        variant="h1"
        className="my-8 text-center !text-[1.5rem] text-dark"
      >
        {t('invite_candidate_title', {
          candidateName: match?.candidate?.firstName
        })}
      </Typography>
      <div className="flex justify-center">
        <Card className="flex w-full max-w-[500px] flex-col justify-center gap-y-4 !p-6">
          <Typography variant="h2" className="mb-2 text-center text-xl">
            {t('contact_details')}
          </Typography>
          <div className="flex flex-col gap-y-4">
            {match?.candidate?.phone && (
              <Link to={`tel:${match?.candidate?.phone}`}>
                <Button
                  variant="info"
                  size="sm"
                  className="w-full !text-lg"
                  onClick={setInvited}
                >
                  {tLiterals('phone')}: {match?.candidate?.phone}
                </Button>
              </Link>
            )}
            {match?.candidate?.email && (
              <Link to={`mailto:${match?.candidate?.email}`}>
                <Button
                  variant="info"
                  size="sm"
                  className="w-full !text-lg"
                  onClick={setInvited}
                >
                  {tLiterals('email')}: {match?.candidate?.email}
                </Button>
              </Link>
            )}
          </div>
          <Divider />
          <Typography variant="body1" className="mb-2 font-medium leading-5">
            {t('invite_candidate_description')}
          </Typography>
          <Link to={`mailto:${match?.candidate?.email}`}>
            <Button
              variant="primary"
              size="sm"
              className="w-full !text-lg"
              onClick={setInvited}
            >
              {t('send_timeslots')}
            </Button>
          </Link>
        </Card>
      </div>
    </ContainerWithBackButton>
  );
};
