import { Button } from '@elements/Button';
import { Input } from '@elements/Input';
import { Attachment, SendArrow } from '@icons/index';
import { useEffect, useRef, useState } from 'react';

export interface ChatInputProps {
  isAnswering: boolean;
  onSend: (message: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const ChatInput = ({
  isAnswering,
  onSend,
  inputRef: externalInputRef
}: ChatInputProps) => {
  const [message, setMessage] = useState('');
  const internalInputRef = useRef<HTMLInputElement>(null);

  // Use the external ref if provided, otherwise use internal ref
  const inputRef = externalInputRef || internalInputRef;

  const handleSend = () => {
    onSend(message);
    setMessage('');
    inputRef?.current?.focus();
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (!isAnswering) {
      setTimeout(() => {
        // Using optional chaining here in case the ref or current is null
        inputRef?.current?.focus();
      }, 200);
    }
  }, [isAnswering, inputRef]);

  return (
    <div className="flex-[0_0] pb-10">
      <Input
        className="!rounded-[20px] !border-[#000] !bg-white !p-6 !px-[55px] text-sm"
        placeholder="Reply to Kora"
        onChange={e => setMessage(e.target.value)}
        value={message}
        inputRef={inputRef}
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
          }
        }}
        startAdornment={
          <Button className="border-none !bg-transparent">
            <Attachment />
          </Button>
        }
        endAdornment={
          <Button className="border-none !bg-transparent" onClick={handleSend}>
            <SendArrow />
          </Button>
        }
        autoGrow
      />
    </div>
  );
};
