import { Notification, NotificationType } from '@models/Notification';
import { defaultPageSize, hasReachedEnd } from '@services/api';
import useSWRInfinite from 'swr/infinite';

export const useNotifications = ({
  pageSize = defaultPageSize
}: { pageSize?: number } = {}) => {
  const {
    data: responses,
    error,
    isLoading,
    setSize,
    mutate
  } = useSWRInfinite(
    index => `/notifications?page=${index + 1}&pageSize=${pageSize}`,
    { revalidateFirstPage: false }
  );

  const handleLoadMore = () => {
    setSize(previousValue => previousValue + 1);
  };

  const notifications: Notification<NotificationType>[] = (responses ?? [])
    .map(response => (response?.items ?? []).map(Notification.fromJson))
    .flat();

  return {
    notifications: notifications as Notification<NotificationType>[],
    isLoading,
    isLoadingMore: responses ? responses.length < pageSize : false,
    isError: Boolean(error),
    hasReachedEnd: responses
      ? hasReachedEnd(responses, Number(pageSize))
      : false,
    handleLoadMore,
    mutate
  };
};
