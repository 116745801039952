import { useToast } from '@elements/Toast';
import { KoraApi } from '@services/api';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useForgotPassword = () => {
  const { t } = useTranslation('login', { keyPrefix: 'forgot_password' });
  const { t: tReset } = useTranslation('login', {
    keyPrefix: 'reset_password'
  });
  const { success, error: showError } = useToast();
  const [loading, setLoading] = useState(false);

  const requestReset = async (email: string) => {
    setLoading(true);
    try {
      await KoraApi.post('/auth/forgot-password', { email });
      success(t('success'));
    } catch (error) {
      showError(t('error'));
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (secret: string, password: string) => {
    try {
      await KoraApi.post(`/auth/forgot-password/${secret}`, { password });
      success(tReset('success'));
    } catch (error) {
      showError(tReset('error'));
    }
  };

  const validateHash = async (secret: string) => {
    try {
      await KoraApi.get(`/auth/forgot-password/${secret}`);
      return true;
    } catch (error) {
      return false;
    }
  };

  const memoized = useMemo(() => {
    return {
      requestReset,
      validateHash,
      resetPassword,
      loading
    };
  }, [requestReset, validateHash, resetPassword, loading]);

  return memoized;
};
