import { ProgressCircle } from '@components/profile';
import { VacancyDraft } from '@components/vacancies/VacancyDraft';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { Divider } from '@elements/Divider';
import { Input } from '@elements/Input';
import { MarkdownViewer } from '@elements/MarkdownViewer';
import { Typography } from '@elements/Typography';
import { Pencil, Trash } from '@icons/index';
import { useBackground } from '@providers/BackgroundProvider';
import { useMarkdownContent } from '@services/use-markdown-content';
import { useVacancyPatch } from '@services/vacancies/patch-vacancy';
import { useDeleteVacancy } from '@services/vacancies/use-delete-vacancy';
import { useVacancy } from '@services/vacancies/use-vacancy';
import { Colors } from '@utils/ColorUtils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

export const VacancyDetailPage = () => {
  useBackground(false);
  const { vacancyId } = useParams();
  const { vacancy } = useVacancy(vacancyId!);
  const { t } = useTranslation('vacancies');
  const { deleteVacancy } = useDeleteVacancy(vacancyId!);
  const navigate = useNavigate();

  const handleDelete = async () => {
    const isDeleted = await deleteVacancy();
    if (isDeleted) {
      navigate('/vacancies');
    }
  };

  const Body = () => {
    const { patch } = useVacancyPatch(vacancyId!);
    const [editing, setEditing] = useState(false);
    const [title, setTitle] = useState(vacancy?.title);
    return (
      <>
        <div className="flex gap-8">
          <div className="flex w-full flex-col justify-between gap-8">
            {editing ? (
              <div className="flex items-center gap-2">
                <Input
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  onKeyDown={async e => {
                    if (e.key === 'Enter') {
                      await patch({ title });
                      setTitle(title);
                      setEditing(false);
                    }
                  }}
                />
                <Button
                  variant="secondary"
                  onClick={async () => {
                    await patch({ title });
                    setTitle(title);
                    setEditing(false);
                  }}
                >
                  Save
                </Button>
              </div>
            ) : (
              <div
                className="group flex items-center gap-2 hover:cursor-pointer hover:text-dark"
                onClick={() => setEditing(true)}
              >
                <Typography
                  variant="h1"
                  className={`line-clamp-2 text-xl font-bold leading-[3rem] ${
                    vacancy?.title ? '' : 'italic'
                  }`}
                  enableTooltip
                  tooltipPlacement="top"
                >
                  {title ?? t('draft')}
                </Typography>
                <div className="h-8 w-8 opacity-0 transition-opacity group-hover:opacity-100">
                  <Pencil width={32} height={32} fill={Colors.dark} />
                </div>
              </div>
            )}
            <Typography variant="h2" className="text-secondary">
              {vacancy?.employer?.companyName}
            </Typography>
          </div>
          {vacancy?.intakeProgress !== undefined && (
            <div className="flex items-center justify-end pl-3">
              <ProgressCircle
                hideButton
                percentage={
                  vacancy?.intakeProgress ? vacancy.intakeProgress * 100 : 0
                }
              />
            </div>
          )}
        </div>
        <Divider className="my-6 w-full" />
        <div className="mb-4 flex">
          <Link to={`/vacancies/${vacancyId}/chat`}>
            <Button variant="info">{t('edit')}</Button>
          </Link>
          <Button variant="void" onClick={handleDelete}>
            <Trash />
          </Button>
        </div>
        <Card>
          {vacancy?.summaryUrl && <VacancyMarkdown url={vacancy.summaryUrl} />}
          {vacancy?.intakeProgress === undefined ||
            (vacancy?.intakeProgress === 0 && (
              <Typography variant="h2" className="text-center text-lg">
                {t('no_information_available')}
              </Typography>
            ))}
          {vacancy && !vacancy.summaryUrl && (
            <VacancyDraft vacancy={vacancy} defaultOpen={true} hideTitle />
          )}
        </Card>
      </>
    );
  };

  return (
    <ContainerWithBackButton
      loading={!vacancy}
      onClickBackButton={() => navigate(`/vacancies`)}
    >
      <Body />
    </ContainerWithBackButton>
  );
};

const VacancyMarkdown = ({ url }: { url: string }) => {
  const { markdownContent } = useMarkdownContent(url);
  return <MarkdownViewer content={markdownContent} />;
};
