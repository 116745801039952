import deloitte from '@assets/deloitte.png';
import ing from '@assets/ing.png';
import { DemoChat } from '@components/home/DemoChat';
import { HomeTitleSection } from '@components/home/TitleSection';
import { Container } from '@elements/Container';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { useTranslation } from 'react-i18next';

export const HomePage = () => {
  const { t } = useTranslation('home');
  const testimonials = [
    { image: deloitte, text: t('testimonials.testimonial_1') },
    { image: ing, text: t('testimonials.testimonial_2') },
    { image: deloitte, text: t('testimonials.testimonial_3') }
  ];
  return (
    <Container className="flex w-full flex-col pt-10">
      <MetaTags title={t('home')} />
      <HomeTitleSection />
      {/* Bullet points and chat */}
      <div className="gap- my-10 grid min-h-[550px] grid-cols-[4fr_5fr] items-center px-10">
        <ul className="list-disc text-xl">
          {(t('description_items', { returnObjects: true }) as string[]).map(
            (item, index) => (
              <li key={index}>
                <Typography variant="body1" className="my-7">
                  {item}
                </Typography>
              </li>
            )
          )}
        </ul>
        <DemoChat />
      </div>
      {/* Testimonials */}
      <div className="my-[100px] animate-raise py-5">
        <Typography
          variant="h2"
          className="px-[10%] text-center !text-[2.5rem] !font-bold leading-10"
          color="dark"
        >
          {t('testimonials.title')}
        </Typography>
        <div className="my-[75px] grid grid-cols-1 gap-[50px] md:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="px-10">
              <div className="mb-4 flex aspect-video items-center justify-center rounded-2xl bg-[#35f] p-[33px]">
                <img src={testimonial.image} alt="Deloitte" />
              </div>
              <Typography
                variant="body1"
                className="mt-5 text-center text-lg font-medium"
              >
                {testimonial.text}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};
