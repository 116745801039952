import { notificationMapper } from '@components/notifications/NotificationMapper';
import { Badge } from '@elements/Badge';
import { Card } from '@elements/Card';
import { Tooltip } from '@elements/Tooltip';
import { Typography } from '@elements/Typography';
import { ChevronRight } from '@icons/index';
import { useAccountContext } from '@providers/UserContext';
import { useNotifications } from '@services/notifications/use-notifications';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface DashboardNotificationsProps {
  className?: string;
}
export const DashboardNotifications = ({
  className
}: DashboardNotificationsProps) => {
  const { t } = useTranslation('notifications');
  const { t: tVacancy } = useTranslation('vacancies');
  const { notifications } = useNotifications({ pageSize: 4 });
  const { user } = useAccountContext();
  return (
    <Card className={`!p-6 ${className}`}>
      <Typography
        color="default"
        variant="h5"
        className="flex items-center gap-2 text-lg font-semibold"
      >
        <Badge color="secondary">{user?.amountUnreadNotifications ?? 0}</Badge>{' '}
        {t('title')}
      </Typography>
      <div className="flex h-full flex-col justify-between">
        <div className="mt-3 flex flex-col gap-4 p-4">
          <ul className="list-disc space-y-6 pl-2">
            {notifications.map(notification => {
              if (!notification) return null;

              const mapper = notificationMapper[notification.type];
              if (!mapper) return null;

              const notificationData = mapper(notification as any, t);
              if (!notificationData) return null;

              return (
                <NotificationItem
                  key={notification.id}
                  to={notificationData.url}
                  content={<Trans>{notificationData.content}</Trans>}
                  linkText={notificationData.goToText}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </Card>
  );
};

const NotificationItem = ({
  to,
  content,
  linkText
}: {
  to: string;
  content: React.ReactNode;
  linkText: string;
}) => {
  return (
    <li>
      <Link
        to={to}
        className="grid grid-cols-[1fr_auto] items-center justify-between gap-2 no-underline"
      >
        <Tooltip content={content}>
          <Typography
            color="default"
            className="line-clamp-2 max-w-[85%] leading-6"
          >
            {content}
          </Typography>
        </Tooltip>
        <Typography
          color="secondary"
          className="flex items-center gap-1 font-semibold"
        >
          {linkText} <ChevronRight className="ml-1 h-3 w-3" />
        </Typography>
      </Link>
    </li>
  );
};
