import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';

let socketInstance: Socket | null = null;

const COOLDOWN_MS = 1000; // 1 second cooldown

export const useSocket = (roomId: string, onMessage?: () => void) => {
  const messageHandlerRef = useRef(onMessage);
  const lastCallTimeRef = useRef<number>(0);

  useEffect(() => {
    messageHandlerRef.current = onMessage;
  }, [onMessage]);

  useEffect(() => {
    if (!socketInstance) {
      socketInstance = io(import.meta.env.VITE_API_URL);
    }

    if (roomId) {
      socketInstance.emit('join', roomId);
    }

    if (onMessage) {
      const handler = () => {
        const now = Date.now();
        if (now - lastCallTimeRef.current >= COOLDOWN_MS) {
          messageHandlerRef.current?.();
          lastCallTimeRef.current = now;
        }
      };

      socketInstance.on('message', handler);

      return () => {
        socketInstance?.off('message', handler);
        if (roomId) {
          socketInstance?.emit('leave', roomId);
        }
      };
    }
  }, [roomId]);

  return socketInstance;
};
