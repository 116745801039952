import { Search as SearchIcon } from '@icons/index';
import { ChangeEvent, useState } from 'react';
import { Input } from './Input';

export interface SearchInputProps {
  onChange?: (value: string) => void;
}

export const SearchInput = ({ onChange }: SearchInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const timeout = setTimeout(() => {
      onChange?.(e.target.value);
    }, 200);
    setSearchTimeout(timeout);
  };

  return (
    <div className={`${isFocused ? 'w-[400px]' : 'w-[150px]'}`}>
      <Input
        className={`h-[34px] !rounded-[20px] !border-[1px] !border-[#000] !bg-transparent py-1 text-sm ${
          !isFocused ? 'placeholder:font-medium placeholder:text-black' : ''
        }`}
        endAdornment={<SearchIcon className="mr-3 w-4 text-gray" />}
        value={value}
        onChange={handleChange}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        placeholder="Search"
      />
    </div>
  );
};
