import { Candidate } from './candidate';
import { Vacancy } from './Vacancy';

export enum MatchingStatus {
  MATCHED = 'MATCHED',
  APPLIED = 'APPLIED',
  REJECTED = 'REJECTED',
  INVITED = 'INVITED',
  DECLINED = 'DECLINED',
  INTERVIEW = 'INTERVIEW',
  DECLINED_INTERVIEW_BY_CANDIDATE = 'DECLINED_INTERVIEW_BY_CANDIDATE',
  DECLINED_INTERVIEW_BY_EMPLOYER = 'DECLINED_INTERVIEW_BY_EMPLOYER',
  JOB_TAKEN = 'JOB_TAKEN'
}

export class Match {
  constructor(
    public readonly id: string,
    public readonly percentage: number,
    public readonly vacancy: Vacancy,
    public readonly candidate: Candidate,
    public readonly status: MatchingStatus,
    public readonly updatedAt: Date
  ) {}

  public static fromJson(json: any) {
    return new Match(
      json.id,
      json.percentage,
      Vacancy.fromJson(json.vacancy),
      Candidate.fromJson(json.candidate),
      json.status,
      new Date(json.updatedAt)
    );
  }
}
