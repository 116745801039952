import { Candidate } from '@models/candidate';
import { KoraApi } from '@services/api';
import { useAccountContext } from 'src/providers/UserContext';

export const useResumeDelete = () => {
  const { user, mutate } = useAccountContext<Candidate>();

  const deleteResume = async () => {
    if (!user) return;

    await KoraApi.delete(`/candidate/resume`);
    mutate();
  };

  return { deleteResume };
};
