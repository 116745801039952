export function formatDate(date: Date, locale = 'en-US', separator = '-') {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

  //@ts-ignore
  const formattedDate = new Intl.DateTimeFormat(locale, options).format(date);

  const [month, day, year] = formattedDate
    .split('/')
    .map(part => part.padStart(2, '0'));

  //   if (locale.startsWith('en-US') || locale.startsWith('ja-JP')) {
  //     // US or similar locales: "MM/dd/YYYY"
  //     return `${month}${separator}${day}${separator}${year}`;

  // Most other locales: "dd/MM/YYYY"
  return `${day}${separator}${month}${separator}${year}`;
}

export function stringifyDateDelta(date: Date) {
  const now = new Date();
  const diffTime = Math.abs(now.getTime() - date.getTime());
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays > 0) {
    return `${diffDays}d`;
  }

  const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
  if (diffHours > 0) {
    return `${diffHours}h`;
  }

  const diffMinutes = Math.floor(diffTime / (1000 * 60));
  return `${diffMinutes}m`;
}
