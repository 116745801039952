import logo from '@assets/logo.svg';
import { MenuItem } from '@components/header/MenuItem';
import { MenuSubItem } from '@components/header/MenuSubItem';
import { NotificationBell } from '@components/header/NotificationBell';
import { Button } from '@elements/Button';
import { AccountType } from '@models/UserAccount';
import { useLogout } from '@services/auth/use-logout';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';

const ProductionHeader = () => (
  <div className="fixed z-10 flex h-[80px] w-full flex-[0_1_auto] justify-center rounded-[0px_0px_20px_20px] !bg-[#F5F5F0] p-0 shadow-[0px_25px_30px_0px_rgba(0,0,0,0.04)]">
    <img src={logo} alt="Logo" />
  </div>
);

const AuthButtons = () => {
  const { t } = useTranslation('header');

  return (
    <div className="flex gap-4">
      <Link to="/register">
        <Button>{t('create_account')}</Button>
      </Link>
      <Link to="/login">
        <Button>{t('login')}</Button>
      </Link>
    </div>
  );
};

const EmployerMenu = ({ t }: { t: (key: string) => string }) => {
  const logout = useLogout();
  return (
    <>
      <MenuItem title="Account">
        <MenuSubItem title={t('settings')} to="/settings" />
        <MenuSubItem title={t('logout')} onClick={logout} />
      </MenuItem>
      <MenuItem title={t('employer.recruitment_funnel')} to="/funnel" />
      <MenuItem title={t('employer.vacancies')} to="/vacancies" />
    </>
  );
};

const CandidateMenu = ({ t }: { t: (key: string) => string }) => {
  const logout = useLogout();
  return (
    <>
      <MenuItem title="Account">
        <MenuSubItem title={t('profile')} to="/profile" />
        <MenuSubItem title={t('logout')} onClick={logout} />
      </MenuItem>
      <MenuItem title={t('recommended_jobs')} to="/jobs" />
      <MenuItem title={t('funnel')} to="/funnel" />
    </>
  );
};

const UserMenu = () => {
  const { t } = useTranslation('header');

  const { user } = useAccountContext();

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex gap-10">
        {user?.accountType === AccountType.CANDIDATE && <CandidateMenu t={t} />}
        {user?.accountType === AccountType.EMPLOYER && <EmployerMenu t={t} />}
      </div>
      <NotificationBell />
    </div>
  );
};

export const Header = () => {
  const { isLoggedIn } = useAccountContext();

  if (import.meta.env.VITE_ENV === 'production') {
    return <ProductionHeader />;
  }

  return (
    <div className="fixed z-10 flex w-full flex-[0_1_auto] justify-between rounded-[0px_0px_20px_20px] !bg-[#F5F5F0] p-[24px_40px] shadow-[0px_25px_30px_0px_rgba(0,0,0,0.04)]">
      <div
        className={`flex items-center gap-2 ${
          isLoggedIn ? 'w-full' : 'justify-between'
        }`}
      >
        <Link to="/">
          <img src={logo} className="me-10 h-[40px]" alt="Logo" />
        </Link>
        {isLoggedIn && <UserMenu />}
      </div>

      {!isLoggedIn && <AuthButtons />}
    </div>
  );
};
