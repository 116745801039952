import { MatchingStatus } from '@models/Match';
import { Notification, NotificationType } from '@models/Notification';
import { TFunction } from 'i18next';

const candidateNewMatchNotification = (
  notification: Notification<NotificationType.CANDIDATE_NEW_MATCH>,
  t: TFunction
) => {
  const { match } = notification.content;

  const content = t('new_match', {
    vacancyTitle: match?.vacancy?.title,
    percentage: match?.percentage
  });

  return {
    content,
    url: `/jobs/${match?.vacancy?.id}`,
    goToText: t('go_to_candidate_match')
  };
};

const candidateMatchChangedNotification = (
  notification: Notification<NotificationType.CANDIDATE_MATCH_CHANGED>,
  t: TFunction
) => {
  const { match } = notification.content;
  let translationKey;
  switch (notification.content.newStatus) {
    case MatchingStatus.REJECTED:
      translationKey = 'employer_rejected';
      break;
    case MatchingStatus.INVITED:
      translationKey = 'employer_invited';
      break;
    case MatchingStatus.DECLINED_INTERVIEW_BY_EMPLOYER:
      translationKey = 'employer_declined_interview';
      break;
  }
  if (!translationKey) {
    return null;
  }

  const content = t(translationKey, {
    vacancyTitle: match?.vacancy?.title,
    employerName: match?.vacancy?.employer?.companyName
  });
  return {
    content,
    url: `/jobs/${match?.vacancy?.id}`,
    goToText: t('go_to_candidate_match')
  };
};

const candidateIntakeReminderNotification = (
  _notification: Notification<NotificationType.CANDIDATE_INTAKE_REMINDER>,
  t: TFunction
) => {
  const content = t('candidate_intake_reminder');
  return {
    content,
    url: '/intake',
    goToText: t('go_to_intake')
  };
};

const candidateInterviewCheckNotification = (
  notification: Notification<NotificationType.CANDIDATE_INTERVIEW_CHECK>,
  t: TFunction
) => {
  const { match } = notification.content;
  const translationKey =
    match?.status === MatchingStatus.JOB_TAKEN
      ? 'candidate_job_taken_check'
      : 'candidate_interview_check';
  const content = t(translationKey, {
    vacancyTitle: match?.vacancy?.title
  });
  return {
    content,
    url: `/jobs/${match?.vacancy?.id}`,
    goToText: t('go_to_candidate_match')
  };
};

const employerIntakeReminderNotification = (
  _notification: Notification<NotificationType.EMPLOYER_INTAKE_REMINDER>,
  t: TFunction
) => {
  const content = t('employer_intake_reminder');
  return {
    content,
    url: '/intake',
    goToText: t('go_to_intake')
  };
};

const employerVacancyIntakeReminderNotification = (
  notification: Notification<NotificationType.EMPLOYER_VACANCY_INTAKE_REMINDER>,
  t: TFunction
) => {
  const { vacancy } = notification.content;
  const content = t('vacancy_intake_reminder', {
    vacancyTitle: vacancy?.title
  });
  return {
    content,
    url: `/vacancies/${vacancy?.id}`,
    goToText: t('go_to_employer_vacancy')
  };
};

const employerInterviewCheckNotification = (
  notification: Notification<NotificationType.EMPLOYER_INTERVIEW_CHECK>,
  t: TFunction
) => {
  const { match } = notification.content;
  const translationKey =
    match?.status === MatchingStatus.JOB_TAKEN
      ? 'employer_job_taken_check'
      : 'employer_interview_check';
  const content = t(translationKey, {
    vacancyTitle: match?.vacancy?.title,
    candidateName:
      match?.candidate?.firstName + ' ' + match?.candidate?.lastName
  });
  return {
    content,
    url: `/matches/${match?.id}`,
    goToText: t('go_to_employer_match')
  };
};

const employerMatchChangedNotification = (
  notification: Notification<NotificationType.EMPLOYER_MATCH_CHANGED>,
  t: TFunction
) => {
  const { match } = notification.content;
  const status = notification.content.newStatus;

  const content =
    status === MatchingStatus.INTERVIEW
      ? t('interview_accepted_verification_label', {
          vacancyTitle: match?.vacancy?.title,
          candidateName:
            match?.candidate?.firstName + ' ' + match?.candidate?.lastName
        })
      : t('candidate_has_applied', {
          vacancyTitle: match?.vacancy?.title,
          candidateName:
            match?.candidate?.firstName + ' ' + match?.candidate?.lastName
        });

  return {
    content,
    url: `/matches/${match?.id}`,
    goToText: t('go_to_employer_match')
  };
};

export const notificationMapper: {
  [K in NotificationType]: (
    notification: Notification<K>,
    t: TFunction
  ) => {
    content: React.ReactNode;
    url: string;
    goToText: string;
  } | null;
} = {
  [NotificationType.CANDIDATE_NEW_MATCH]: candidateNewMatchNotification,
  [NotificationType.CANDIDATE_MATCH_CHANGED]: candidateMatchChangedNotification,
  [NotificationType.CANDIDATE_INTAKE_REMINDER]:
    candidateIntakeReminderNotification,
  [NotificationType.CANDIDATE_INTERVIEW_CHECK]:
    candidateInterviewCheckNotification,
  [NotificationType.EMPLOYER_INTAKE_REMINDER]:
    employerIntakeReminderNotification,
  [NotificationType.EMPLOYER_VACANCY_INTAKE_REMINDER]:
    employerVacancyIntakeReminderNotification,
  [NotificationType.EMPLOYER_INTERVIEW_CHECK]:
    employerInterviewCheckNotification,
  [NotificationType.EMPLOYER_MATCH_CHANGED]: employerMatchChangedNotification
};
