import { Match, MatchingStatus } from './Match';
import { Vacancy } from './Vacancy';

export enum NotificationType {
  CANDIDATE_NEW_MATCH = 'CANDIDATE_NEW_MATCH',
  CANDIDATE_MATCH_CHANGED = 'CANDIDATE_MATCH_CHANGED',
  CANDIDATE_INTAKE_REMINDER = 'CANDIDATE_INTAKE_REMINDER',
  CANDIDATE_INTERVIEW_CHECK = 'CANDIDATE_INTERVIEW_CHECK',
  EMPLOYER_INTAKE_REMINDER = 'EMPLOYER_INTAKE_REMINDER',
  EMPLOYER_VACANCY_INTAKE_REMINDER = 'EMPLOYER_VACANCY_INTAKE_REMINDER',
  EMPLOYER_INTERVIEW_CHECK = 'EMPLOYER_INTERVIEW_CHECK',
  EMPLOYER_MATCH_CHANGED = 'EMPLOYER_MATCH_CHANGED'
}

export type NotificationContentType = {
  [NotificationType.CANDIDATE_NEW_MATCH]: {
    match: Match;
  };
  [NotificationType.CANDIDATE_MATCH_CHANGED]: {
    match: Match;
    previousStatus: MatchingStatus;
    newStatus: MatchingStatus;
  };
  [NotificationType.CANDIDATE_INTAKE_REMINDER]: {
    timeDelta: 24 | 48 | 72;
  };
  [NotificationType.CANDIDATE_INTERVIEW_CHECK]: {
    match: Match;
    timeDelta: 24 | 48 | 72;
  };
  [NotificationType.EMPLOYER_INTAKE_REMINDER]: {
    timeDelta: 24 | 48 | 72;
    match: Match;
  };
  [NotificationType.EMPLOYER_VACANCY_INTAKE_REMINDER]: {
    vacancy: Vacancy;
    timeDelta: 24 | 48 | 72;
  };
  [NotificationType.EMPLOYER_INTERVIEW_CHECK]: {
    match: Match;
    timeDelta: 24 | 48 | 72;
  };
  [NotificationType.EMPLOYER_MATCH_CHANGED]: {
    match: Match;
    previousStatus: MatchingStatus;
    newStatus: MatchingStatus;
  };
};
export class Notification<T extends NotificationType> {
  constructor(
    public id: string,
    public type: T,
    public content: NotificationContentType[T],
    public read: boolean,
    public createdAt: Date,
    public updatedAt: Date
  ) {}

  static fromJson(json: any) {
    return new Notification(
      json.id,
      json.type,
      json.content,
      json.read,
      new Date(json.createdAt),
      new Date(json.updatedAt)
    );
  }
}
