import { ReactNode } from 'react';

type Placement = 'top' | 'bottom' | 'left' | 'right';

interface TooltipProps {
  children: ReactNode;
  content: string | ReactNode;
  placement?: Placement;
}

export const Tooltip = ({
  children,
  content,
  placement = 'bottom'
}: TooltipProps) => {
  const placementStyles: Record<Placement, string> = {
    top: 'bottom-full left-1/2 -translate-x-1/2 mb-1',
    bottom: 'top-full left-1/2 -translate-x-1/2 mt-1',
    left: 'right-full top-1/2 -translate-y-1/2 mr-1',
    right: 'left-full top-1/2 -translate-y-1/2 ml-1'
  };

  const arrowStyles: Record<Placement, string> = {
    top: '-bottom-1 left-1/2 -translate-x-1/2',
    bottom: '-top-1 left-1/2 -translate-x-1/2',
    left: '-right-1 top-1/2 -translate-y-1/2',
    right: '-left-1 top-1/2 -translate-y-1/2'
  };

  return (
    <div className="group relative inline-block">
      <div
        className={`invisible absolute z-[9999] w-auto min-w-max transform group-hover:visible ${placementStyles[placement]}`}
      >
        <div className="relative z-10 whitespace-normal break-words rounded-md bg-dark-gray px-3 py-2 text-sm text-white shadow-lg">
          {content}
          <div
            className={`absolute h-2 w-2 rotate-45 transform bg-dark-gray ${arrowStyles[placement]}`}
          />
        </div>
      </div>
      {children}
    </div>
  );
};
