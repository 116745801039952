import { RoundedContainer } from '@components/RoundedContainer';
import { Tooltip } from '@elements/Tooltip';
import { Typography } from '@elements/Typography';
import { Notification, NotificationType } from '@models/Notification';
import { KoraApi } from '@services/api';
import { useNotifications } from '@services/notifications/use-notifications';
import { stringifyDateDelta } from '@utils/DateUtils';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { notificationMapper } from './NotificationMapper';

export interface NotificationCardProps {
  notification: Notification<NotificationType>;
}

const Bubble = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8.12012" r="8" fill="#3B55F6" />
    </svg>
  );
};

export const NotificationCard = ({ notification }: NotificationCardProps) => {
  const { t } = useTranslation('notifications');
  const notificationData = notificationMapper[notification.type](
    notification as any,
    t
  );
  if (!notificationData) {
    return null;
  }
  const { content, url } = notificationData;
  const { mutate } = useNotifications();
  const navigate = useNavigate();

  return (
    <RoundedContainer
      className="mb-4 flex flex-row justify-between p-6 transition-all duration-300 hover:scale-[1.02] hover:cursor-pointer"
      onClick={async (e: React.MouseEvent) => {
        await KoraApi.put(`/notifications/${notification.id}/read`, {});
        mutate();
        if (e.ctrlKey) {
          window.open(url, '_blank');
        } else {
          navigate(url);
        }
      }}
    >
      <div className="flex min-w-0 flex-1 flex-row">
        <div className="w-10 flex-shrink-0">
          {!notification.read && <Bubble />}
        </div>
        <div className="min-w-0 flex-1">
          <div className="truncate">
            <Tooltip content={<Trans>{content}</Trans>} placement="top">
              <span className="inline-block w-full truncate">
                <Trans>{content}</Trans>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="ms-10 flex-shrink-0">
        <Typography variant="caption">
          {stringifyDateDelta(notification.createdAt)}
        </Typography>
      </div>
    </RoundedContainer>
  );
};
