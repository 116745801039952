export class ChatRoom {
  constructor(
    public id: string,
    public canComplete: boolean
  ) {}

  static fromJson(json: any): ChatRoom {
    return new ChatRoom(json.id, json.canComplete);
  }
}
