import { Match, MatchingStatus } from '@models/Match';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

interface Funnel {
  [MatchingStatus.APPLIED]: Match[];
  [MatchingStatus.DECLINED]: Match[];
  [MatchingStatus.INVITED]: Match[];
  [MatchingStatus.REJECTED]: Match[];
  [MatchingStatus.INTERVIEW]: Match[];
  total: number;
}

export const EmployerFunnelStatuses = [
  MatchingStatus.APPLIED,
  MatchingStatus.DECLINED,
  MatchingStatus.INVITED,
  MatchingStatus.REJECTED,
  MatchingStatus.INTERVIEW
] as const;

export type EmployerFunnelStatus = (typeof EmployerFunnelStatuses)[number];

export const useEmployerFunnel = (args?: {
  vacancyIds?: string[];
  search?: string;
}) => {
  const [url, setUrl] = useState(`/employer/funnel`);
  const { data, ...swr } = useSWR(url);

  useEffect(() => {
    let url = `/employer/funnel?`;
    if (args?.vacancyIds) {
      url += `vacancyIds=${args.vacancyIds.join(',')}`;
    }
    if (args?.search) {
      url += `&search=${args.search}`;
    }
    setUrl(url);
  }, [args?.vacancyIds, args?.search]);

  const matches: Match[] = data?.map((match: any) => {
    return Match.fromJson(match);
  });

  const funnel: Funnel = {
    [MatchingStatus.APPLIED]: [],
    [MatchingStatus.DECLINED]: [],
    [MatchingStatus.INVITED]: [],
    [MatchingStatus.REJECTED]: [],
    [MatchingStatus.INTERVIEW]: [],
    total: matches?.length || 0
  };

  matches?.forEach(match => {
    let status = match.status;
    if (status === MatchingStatus.DECLINED_INTERVIEW_BY_CANDIDATE) {
      status = MatchingStatus.DECLINED;
    }
    if (status === MatchingStatus.DECLINED_INTERVIEW_BY_EMPLOYER) {
      status = MatchingStatus.REJECTED;
    }
    if (
      status !== MatchingStatus.MATCHED &&
      status !== MatchingStatus.JOB_TAKEN
    ) {
      funnel[status].push(match);
    }
  });

  return {
    funnel,
    ...swr
  };
};
