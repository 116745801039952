import { formatDate } from '@utils/DateUtils';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from './Calendar';
import { Input } from './Input';
import OutsideAlerter from './OutsideAlerter';

export interface IDateInput {
  label?: string;
  defaultValue?: Date;
  className?: string;
  value?: Date;
  onChange?: (e: Date) => void;
  variant?: 'default' | 'compact';
  placeholder?: string;
}

export const DateInput = ({
  label,
  className,
  onChange,
  value: propsValue,
  defaultValue,
  variant = 'default',
  placeholder,
  ...props
}: IDateInput) => {
  const { i18n } = useTranslation();
  const [value, setValue] = useState(
    defaultValue ? formatDate(defaultValue, i18n.language) : ''
  );

  useEffect(() => {
    if (propsValue && !value) {
      setValue(formatDate(propsValue, i18n.language));
    }
  }, [propsValue]);

  const [isFocused, setIsFocused] = useState(false);

  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>(
    'bottom'
  );

  useEffect(() => {
    if (isFocused) {
      const input = document.querySelector(
        '[data-testid="date-input"]'
      ) as HTMLElement;
      if (input) {
        const inputRect = input.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const spaceBelow = viewportHeight - inputRect.bottom;
        const requiredSpace = 420; // height of calendar

        setDropdownPosition(spaceBelow >= requiredSpace ? 'bottom' : 'top');
      }
    }
  }, [isFocused]);

  const validChars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '/',
    '-'
  ];

  const isValidDate = (date: string) => {
    return (
      moment(date, 'DD-MM-YYYY', true).isValid() ||
      moment(date, 'MM-DD-YYYY', true).isValid() ||
      moment(date, 'DD/MM/YYYY', true).isValid() ||
      moment(date, 'MM/DD/YYYY', true).isValid()
    );
  };

  const stringToDate = (date: string) => {
    date = date.replaceAll('/', '-');
    return moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY'], true).toDate();
  };

  return (
    <OutsideAlerter onClickOutside={() => setIsFocused(false)}>
      <div className="relative">
        <Input
          label={label}
          data-testid="date-input"
          placeholder={placeholder}
          className={className}
          onChange={e => {
            setValue(e.target.value);
            if (isValidDate(e.target.value)) {
              const date = stringToDate(e.target.value);
              setValue(formatDate(date, i18n.language));
              onChange && onChange(date);
            }
          }}
          allowedChars={validChars}
          onFocus={() => setIsFocused(true)}
          value={value}
          variant={variant}
          {...props}
        />
        {isFocused && (
          <div
            className={`absolute z-[9999] min-h-[420px] min-w-[300px] transition duration-200 ${
              dropdownPosition === 'top' ? 'bottom-full mb-1' : 'top-full mt-1'
            }`}
          >
            <Calendar
              data-testid="date-picker"
              locale={i18n.language}
              defaultValue={
                value && isValidDate(value) ? stringToDate(value) : undefined
              }
              onChange={e => {
                const formattedDate = formatDate(e, i18n.language);
                setValue(formattedDate);
                setIsFocused(false);
                onChange && onChange(e);
              }}
            />
          </div>
        )}
      </div>
    </OutsideAlerter>
  );
};
