import { Button } from '@elements/Button';
import { Typography } from '@elements/Typography';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface ICTA {
  title: string | ReactNode;
  description: string;
  cta: string | ReactNode;
  statusMessage?: string | ReactNode;
  onClick?: () => void;
  link?: string;
}
export { ResumeCTA } from './ResumeCTA';
export const CTA = ({
  title,
  description,
  cta,
  statusMessage,
  onClick,
  link
}: ICTA) => {
  return (
    <div className="flex flex-col gap-3">
      <Typography variant="smallTitle">{title}</Typography>
      {statusMessage && <div className="text-xs">{statusMessage}</div>}
      <div className="text-xs">{description}</div>
      <div>
        {link ? (
          <Link to={link}>
            <Button onClick={onClick} size="xs" className="p-[6px_16px]">
              {cta}
            </Button>
          </Link>
        ) : (
          <Button onClick={onClick} size="xs" className="p-[6px_16px]">
            {cta}
          </Button>
        )}
      </div>
    </div>
  );
};
