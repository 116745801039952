import { ChatBox } from '@components/chat/ChatBox';
import { BackButton } from '@elements/BackButton';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { ProgressBar } from '@elements/ProgressBar';
import { Typography } from '@elements/Typography';
import { Employer } from '@models/Employer';
import { useChat } from '@services/chat/use-chat';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';

export const EmployerIntakePage = () => {
  const { t } = useTranslation('chat', {
    keyPrefix: 'employer_intake'
  });
  const { user } = useAccountContext<Employer>();
  const [currentStep, setCurrentStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [canCreateVacancies, setCanCreateVacancies] = useState(false);

  const { completeConversation, canComplete } = useChat({
    about: { aboutType: 'UserAccount', aboutId: user!.id }
  });

  const handleCompleteAction = useCallback(async () => {
    try {
      await completeConversation();
    } catch (error) {
      console.error('Error completing conversation:', error);
    }
  }, [completeConversation]);

  useEffect(() => {
    let _step = 1;
    const fields = user?.accountInfo;
    if (fields) {
      const { companyName, website, phone, industry, address } = fields;
      if (companyName && website && phone && industry && address) {
        _step = 2;
      }
      if (canComplete) {
        _step = 3;
        setIsComplete(true);
      }
      if (user?.accountInfo.descriptionUrl) {
        setCanCreateVacancies(true);
      }
    }
    setCurrentStep(_step);
  }, [user, canComplete]);

  const steps = [
    { label: t('progress_bar.step_1'), active: currentStep === 1 },
    { label: t('progress_bar.step_2'), active: currentStep === 2 },
    { label: t('progress_bar.step_3'), active: currentStep === 3 }
  ];

  const Header = () => {
    return (
      <Container>
        <div className="grid grid-cols-[1fr,4fr,1fr] py-4">
          <div className="flex items-start justify-start gap-2 font-bold">
            <BackButton />
          </div>
          <div className="w-full text-center">
            <Typography variant="h2" className="mb-3 text-primary">
              {t('title')}
            </Typography>
            <Typography variant="body1" className="mb-4 text-secondary">
              {t('description')}
            </Typography>
            <ProgressBar steps={steps} />
          </div>
        </div>
      </Container>
    );
  };
  return (
    <div className="flex h-full max-h-[calc(100vh-90px)] w-full flex-col">
      <Header />
      {user && (
        <ChatBox
          about={{ aboutType: 'UserAccount', aboutId: user.id }}
          cta={
            isComplete ? (
              <>
                <Button
                  variant="info"
                  className="font-medium"
                  onClick={handleCompleteAction}
                >
                  {t('complete_profile_cta')}
                </Button>
                {canCreateVacancies && (
                  <Link to="/vacancies">
                    <Button variant="info" className="ms-3 font-medium">
                      {t('create_vacancies_cta')}
                    </Button>
                  </Link>
                )}
              </>
            ) : undefined
          }
        />
      )}
    </div>
  );
};
