import { Candidate } from '@models/candidate';
import { Employer } from '@models/Employer';
import { UserAccount } from '@models/UserAccount';
import useAccount from '@services/account/use-account';
import { useNotifications } from '@services/notifications/use-notifications';
import { createContext, ReactNode, useContext, useEffect } from 'react';
import { io } from 'socket.io-client';

// Define `IUserContext` to take children as its prop
export interface IUserContext {
  children: ReactNode;
}

// Generic UserContextType that includes the generic `T` for accountInfo type
export interface UserContextType<T extends Candidate | Employer | null> {
  user: UserAccount<T> | null;
  isLoggedIn: boolean;
  isLoading?: boolean;
  mutate: () => void;
}

// Using a generic type `T` for the context, defaulting to `any`
export const AccountContext = createContext<UserContextType<any>>({
  user: null,
  isLoggedIn: false,
  isLoading: true,
  mutate: () => {}
});

export const AccountContextProvider = ({ children }: IUserContext) => {
  const { user, isLoading, mutate } = useAccount();
  const { mutate: mutateNotifications } = useNotifications();

  const subscribeToUserUpdates = (userId: string) => {
    const socket = io(import.meta.env.VITE_API_URL);
    socket.emit('join', 'user-updates-' + userId);
    socket.on('connection', socket => {
      socket.join(userId);
    });
    socket.on('message', () => {
      mutate();
    });

    return socket;
  };

  const subscribeToNotifications = (userId: string) => {
    const socket = io(import.meta.env.VITE_API_URL);
    socket.emit('join', 'notifications-' + userId);
    socket.on('message', () => {
      mutateNotifications();
    });

    return socket;
  };

  useEffect(() => {
    if (user) {
      const socket = subscribeToUserUpdates(user.id);
      const notificationSocket = subscribeToNotifications(user.id);
      return () => {
        socket.disconnect();
        notificationSocket.disconnect();
      };
    }
  }, [user]);

  return (
    <AccountContext.Provider
      value={{ user, isLoggedIn: Boolean(user), isLoading, mutate }}
    >
      {children}
    </AccountContext.Provider>
  );
};

// Adjusted hook to include generic type `T`
export const useAccountContext = <T extends Candidate | Employer | null>() =>
  useContext(AccountContext) as UserContextType<T>;
