import { ChatBox } from '@components/chat/ChatBox';
import { BackButton } from '@elements/BackButton';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { ProgressBar } from '@elements/ProgressBar';
import { Typography } from '@elements/Typography';
import { Employer } from '@models/Employer';
import { useChat } from '@services/chat/use-chat';
import { useVacancy } from '@services/vacancies/use-vacancy';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';

export const VacancyChatPage = () => {
  const { vacancyId } = useParams();
  const { t } = useTranslation('chat', {
    keyPrefix: 'vacancy_intake'
  });
  const { user } = useAccountContext<Employer>();
  const [currentStep, setCurrentStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const navigate = useNavigate();

  const { vacancy } = useVacancy(vacancyId!);

  const { completeConversation, canComplete } = useChat({
    about: { aboutType: 'Vacancy', aboutId: vacancyId! }
  });

  const handleCompleteAction = useCallback(async () => {
    try {
      await completeConversation();
    } catch (error) {
      console.error('Error completing conversation:', error);
    }
  }, [completeConversation]);

  useEffect(() => {
    setCurrentStep(1);
    if (vacancy?.originalUrl) {
      setCurrentStep(2);
    }
    if (canComplete) {
      setCurrentStep(3);
      setIsComplete(true);
    }
  }, [vacancy]);

  const steps = [
    { label: t('progress_bar.step_1'), active: currentStep === 1 },
    { label: t('progress_bar.step_2'), active: currentStep === 2 },
    { label: t('progress_bar.step_3'), active: currentStep === 3 }
  ];

  const Header = () => {
    return (
      <Container>
        <div className="grid grid-cols-[1fr,4fr,1fr] py-4">
          <div className="flex items-start justify-start gap-2 font-bold">
            <BackButton
              onClick={() => {
                navigate(`/vacancies/${vacancyId}`);
              }}
            />
          </div>
          <div className="w-full text-center">
            <Typography variant="h2" className="mb-3 text-primary">
              {t('title')}
            </Typography>
            <Typography variant="body1" className="mb-4 text-secondary">
              {t('description')}
            </Typography>
            <ProgressBar steps={steps} />
          </div>
        </div>
      </Container>
    );
  };
  return (
    <div className="flex h-full max-h-[calc(100vh-90px)] w-full flex-col">
      <Header />
      {user && (
        <ChatBox
          about={{ aboutType: 'Vacancy', aboutId: vacancyId! }}
          cta={
            isComplete ? (
              <Button
                variant="info"
                className="font-medium"
                onClick={handleCompleteAction}
              >
                {t('complete_conversation')}
              </Button>
            ) : undefined
          }
        />
      )}
    </div>
  );
};
