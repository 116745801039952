import PlaceholderCard from '@assets/placeholder-card.svg';
import { CandidateFunnelCard } from '@components/funnel/CandidateFunnelCard';
import { Container } from '@elements/Container';
import { PopupCTA } from '@elements/PopupCTA';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { Candidate } from '@models/candidate';
import { MatchingStatus } from '@models/Match';
import { usePopup } from '@providers/PopupProvider';
import {
  CandidateFunnelStatus,
  CandidateFunnelStatuses,
  useCandidateFunnel
} from '@services/candidate/use-candidate-funnel';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

export const FunnelPage = () => {
  const { t } = useTranslation('funnel');
  const { user } = useAccountContext<Candidate>();
  const isMobile = useIsMobile();
  const { funnel } = useCandidateFunnel();
  const order = [
    MatchingStatus.APPLIED,
    MatchingStatus.REJECTED,
    MatchingStatus.INVITED,
    MatchingStatus.DECLINED,
    MatchingStatus.INTERVIEW
  ];
  const { show } = usePopup();
  useEffect(() => {
    if (user?.accountInfo?.intakeProgress !== 100) {
      show({
        body: (
          <PopupCTA
            title={t('intake_incomplete')}
            buttonText={t('go_to_intake')}
            link="/intake"
          />
        ),
        closeButton: false
      });
    }
  }, [user?.accountInfo?.intakeProgress]);

  const PlaceholderCards = () => {
    const titles = [
      t('applied'),
      t('rejected'),
      t('invited'),
      t('declined'),
      t('interview')
    ];
    return (
      <>
        {Array.from({ length: 5 }).map((_, index) => (
          <FunnelColumn
            title={titles[index]}
            showDivider={index !== 4}
            key={index}
          >
            <img src={PlaceholderCard} className="w-full" key={index} />
          </FunnelColumn>
        ))}
      </>
    );
  };

  const FunnelColumn = ({
    title,
    children,
    showDivider = true
  }: {
    title: string;
    children: React.ReactNode;
    showDivider?: boolean;
  }) => {
    return (
      <div
        className={`flex h-full flex-col gap-4 pr-3 ${
          showDivider ? 'border-r border-gray' : ''
        }`}
      >
        <Typography variant="h5" className="px-1 pb-5">
          {title}
        </Typography>
        <div className="flex flex-col gap-6">{children}</div>
      </div>
    );
  };

  return (
    <Container className={`${!isMobile && 'py-10'} relative`}>
      <div className="flex flex-col justify-between gap-6 pb-4">
        <Typography variant="h1">{t('application_funnel')}</Typography>
      </div>
      {!funnel || funnel.total === 0 ? (
        <div className="mt-10 flex gap-4">
          <PlaceholderCards />
        </div>
      ) : (
        <div className="mt-10 grid h-[90%] grid-cols-1 gap-4 md:grid-cols-5">
          {order.map((status, index) => {
            if (status !== MatchingStatus.MATCHED) {
              let _status = status;
              if (status === MatchingStatus.DECLINED_INTERVIEW_BY_CANDIDATE) {
                _status = MatchingStatus.DECLINED;
              }

              if (!CandidateFunnelStatuses.includes(_status as any)) {
                return;
              }
              const validStatus = _status as CandidateFunnelStatus;
              return (
                <FunnelColumn
                  title={t(validStatus.toLowerCase())}
                  showDivider={index !== 4}
                  key={index}
                >
                  {funnel[validStatus].map(match => (
                    <CandidateFunnelCard match={match} />
                  ))}
                </FunnelColumn>
              );
            }
          })}
        </div>
      )}
    </Container>
  );
};
