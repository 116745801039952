import { KoraApi } from '@services/api';

export const useResumeDownload = (matchId: string) => {
  const download = async () => {
    try {
      const path = `/employer/funnel/${matchId}/resume`;
      const response = await KoraApi.get(path, {}, { responseType: 'blob' });

      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `resume-${matchId}.pdf`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);

      // Cleanup blob URL
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading resume:', error);
    }
  };

  return { download };
};
