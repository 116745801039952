import { usePopup } from '@providers/PopupProvider';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import { Typography } from './Typography';

interface PopupCTAProps {
  title: string;
  buttonText: string;
  link: string;
}

export const PopupCTA = ({ title, buttonText, link }: PopupCTAProps) => {
  const { hide } = usePopup();
  return (
    <div data-testid="popup-cta">
      <Typography variant="h2" className="mb-[40px] text-dark">
        {title}
      </Typography>
      <Link to={link}>
        <Button variant="secondary" onClick={hide}>
          {buttonText}
        </Button>
      </Link>
    </div>
  );
};
