import WavingHand from '@assets/waving_hand.png';
import { ChatBubble } from '@components/chat';
import { ChatBaseBubble } from '@components/chat/ChatBubble';
import { Button } from '@elements/Button';
import { Typography } from '@elements/Typography';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const DemoChat = () => {
  const [secondMessage, setSecondMessage] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [redirectMessageVisible, setRedirectMessageVisible] = useState(false);

  const { t } = useTranslation('home');
  useEffect(() => {
    setTimeout(() => {
      setSecondMessage(true);
    }, 1000);
  }, []);

  const selectRole = (role: string) => {
    setSelectedRole(role);
    setTimeout(() => {
      setRedirectMessageVisible(true);
    }, 3000);
  };

  const roleSelector = () => {
    return (
      <ChatBaseBubble
        className="animation-delay-1000 mt-3 max-w-[70%] animate-fade-in"
        isUser={false}
        message={
          <>
            <Typography variant="body1">{t('role_selector.title')}</Typography>
            <div className="mt-5">
              <Button
                disabled={selectedRole !== null}
                variant="secondary"
                className="mb-4 text-left font-semibold"
                onClick={() => selectRole('employer')}
              >
                {t('role_selector.employer')}
              </Button>
              <Button
                disabled={selectedRole !== null}
                variant="secondary"
                className="text-left font-semibold"
                onClick={() => selectRole('candidate')}
              >
                {t('role_selector.candidate')}
              </Button>
            </div>
          </>
        }
      ></ChatBaseBubble>
    );
  };

  const redirectMessage = () => {
    return (
      <ChatBaseBubble
        className="mt-5 animate-fade-in"
        isUser={false}
        message={
          <>
            <Typography variant="body1">
              {t('role_selector.redirect')}
            </Typography>
            <div className="flex justify-start">
              <Link to={`/register?type=${selectedRole?.toUpperCase()}`}>
                <Button variant="secondary" className="mt-5">
                  {t('role_selector.redirect_button')}
                </Button>
              </Link>
            </div>
          </>
        }
      />
    );
  };

  return (
    <div className="h-full rounded-lg bg-[#ffffff99] p-4">
      <ChatBubble
        message={<img src={WavingHand} width={32} alt="Waving hand" />}
        className="animate-fade-in"
        isUser={false}
        isLast={true}
        messageId="1"
        translatable={false}
        quickActions={[]}
        sendMessage={() => {}}
      />
      {secondMessage && roleSelector()}
      {selectedRole && (
        <ChatBubble
          className="mt-3 animate-fade-in"
          message={t(`role_selector.${selectedRole}`)}
          isUser={true}
          messageId="2"
          translatable={false}
          quickActions={[]}
          sendMessage={() => {}}
        />
      )}
      {redirectMessageVisible && redirectMessage()}
    </div>
  );
};
